import { useNavigate } from 'react-router';
import { MenuItem } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import {
  ClientDeliverable,
  CompanySet,
  CustomColumn,
  Deliverable,
  DeliverableOutput,
  PipelineFilter,
  PipelineType,
} from '@revelio/data-access';
import { PagePaths } from '@revelio/core';

import {
  DOWNLOAD_DELIVERY_BUCKET,
  DOWNLOAD_DELIVERY_FILE_PREFIX,
  getUniqueFilePrefixName,
  OLD_DOWNLOAD_DELIVERY_BUCKET,
  OLD_DOWNLOAD_DELIVERY_FILE_PREFIX,
} from '../delivery/delivery.model';

import '../../../styles/table-custom.scss';
import { DeliverablePaths } from '../deliverables-page';
import {
  deliverablesStore,
  setClient,
  setDuplicateDatasetNaming,
  setInputRefs,
  setLag_data,
  setName,
} from '../deliverables.repository';
import { setEntities } from '@ngneat/elf-entities';
import {
  Deliverable as DataBuilderDeliverable,
  isCategoryCase,
  isCompanyInfoPipeline,
  isIndividualPipeline,
  removeEmptyValuesAndTypename,
} from '../deliverables.model';
import { transformDeprecatedOperationTypes } from '../confirmation/custom-step';
import { getDatasetId } from '../data-set.model';
import { getCommonDeliveryFilePrefix } from './status.api';
import { useSelectionLists } from '@revelio/filtering';
import { getRequiredSelectionListNamesForCustomStepDeserialisation } from '../columns/dataset-conditions/serialize-conditions.api';
import produce from 'immer';
import { deserializeFilterMultiGranularitySelectionListVariables } from '../columns/filters/serialize-filters.api';
import { deserializeCustomColumnMultiGranularitySelectionListVariables } from '../columns/custom/serialize-custom-columns.api';

const renameToNewFormat = (oldS3FilePrefix: string) => {
  const folders = oldS3FilePrefix.split('/');
  const foldersToKeep = folders.slice(1).join('/');
  const clientName = foldersToKeep.split('_')[0];
  const ending = foldersToKeep.split('_').slice(1).join('_');
  const newRoute = `${DOWNLOAD_DELIVERY_FILE_PREFIX}/${clientName}/${ending}`;
  return newRoute;
};

export const DuplicateEditMenuItem = ({
  deliverable,
}: {
  deliverable?: ClientDeliverable;
}) => {
  const navigate = useNavigate();
  const selectionLists = useSelectionLists(
    getRequiredSelectionListNamesForCustomStepDeserialisation({
      deliverables: deliverable?.deliverables as DeliverableOutput[],
    })
  );
  return (
    <MenuItem
      data-testid="duplicate_and_edit_button"
      icon={<CopyIcon />}
      onClick={() => {
        const deliverableCompanySelection = deliverable?.deliverables.find(
          (del) =>
            del?.company_reference ||
            (del?.company_sets && del.company_sets.length)
        );
        const datasetDuplicateIdTracker: {
          [dataset: string]: string;
        } = {};
        const commonDeliveryFilePrefix = getCommonDeliveryFilePrefix(
          (deliverable?.deliverables as Deliverable[] | undefined) || []
        );
        const savedDuplicateDatasetNaming: {
          [deliverableId: string]: string;
        } = {};
        // get common file location prefix
        deliverablesStore.update(
          setEntities(
            deliverable?.deliverables.map((del) => {
              const filtersWithoutDeprecatedConditionOperations =
                del?.pipeline.filters?.map((filter) =>
                  produce(filter, (deserialisedFilter) => {
                    deserialisedFilter?.conditions?.forEach((condition) => {
                      condition.operation = transformDeprecatedOperationTypes(
                        condition?.operation
                      );
                    });
                  })
                );
              const customColumnWithoutDeprecatedConditionOperations =
                del?.pipeline.custom_columns?.map((col) =>
                  produce(col, (deserialisedColumn) => {
                    deserialisedColumn?.step?.forEach((step) => {
                      if (!isCategoryCase(step)) {
                        return;
                      }

                      step?.case?.conditions?.forEach((condition) => {
                        if (!condition) {
                          return;
                        }

                        condition.operation = transformDeprecatedOperationTypes(
                          condition?.operation
                        );
                      });
                    });
                  })
                );
              // fix deprecations

              const pipelineType = del?.pipeline.pipeline_type as PipelineType;
              let id = `${getDatasetId(pipelineType)}`;
              const hasMultiplePipelineTypeDeliverables =
                deliverable?.deliverables.filter(
                  (savedDeliverable) =>
                    savedDeliverable?.pipeline.pipeline_type === pipelineType
                ).length > 1;
              if (hasMultiplePipelineTypeDeliverables) {
                const previousDuplicateId =
                  datasetDuplicateIdTracker[pipelineType];
                if (previousDuplicateId) {
                  const newDuplicateId = String.fromCharCode(
                    previousDuplicateId.charCodeAt(0) + 1
                  );
                  id = `${id}${newDuplicateId}`;
                  datasetDuplicateIdTracker[pipelineType] = newDuplicateId;
                } else {
                  id = `${id}a`;
                  datasetDuplicateIdTracker[pipelineType] = 'a';
                }
              }
              // get datasetId

              const duplicateDatasetUniqueLocation = getUniqueFilePrefixName(
                del?.s3_delivery?.s3_file_prefix ||
                  del?.snowflake_delivery?.snowflake_file_prefix,
                commonDeliveryFilePrefix
              );
              if (duplicateDatasetUniqueLocation) {
                savedDuplicateDatasetNaming[id] =
                  duplicateDatasetUniqueLocation;
              }
              const hasS3Delivery = del?.s3_delivery;
              const hasSnowflake = del?.snowflake_delivery;
              const cleanedDuplicateDeliverable = removeEmptyValuesAndTypename({
                id,
                ...del,
                pipeline: {
                  ...del?.pipeline,
                  // gql can return null for these which is not supported by the data builder dashboard ts types
                  custom_columns:
                    deserializeCustomColumnMultiGranularitySelectionListVariables(
                      {
                        customColumns:
                          customColumnWithoutDeprecatedConditionOperations as
                            | CustomColumn[]
                            | undefined,
                        selectionLists,
                      }
                    ),
                  filters:
                    deserializeFilterMultiGranularitySelectionListVariables({
                      filters: filtersWithoutDeprecatedConditionOperations as
                        | PipelineFilter[]
                        | undefined,
                      selectionLists,
                    }),
                  ...(isIndividualPipeline(
                    del?.pipeline.pipeline_type as PipelineType
                  )
                    ? {
                        individual_user_configuration: {
                          current: false,
                          full_history: false,
                          ...del?.pipeline.individual_user_configuration,
                        },
                      }
                    : {}),
                  ...(isCompanyInfoPipeline(
                    del?.pipeline.pipeline_type as PipelineType
                  )
                    ? {
                        company_info_configuration: {
                          include_subsidiaries: false,
                          ...del?.pipeline?.company_info_configuration,
                        },
                      }
                    : {}),
                },
                ...(hasS3Delivery
                  ? {
                      s3_delivery: {
                        ...del.s3_delivery,
                        s3_bucket:
                          del.s3_delivery?.s3_bucket ===
                          OLD_DOWNLOAD_DELIVERY_BUCKET
                            ? DOWNLOAD_DELIVERY_BUCKET
                            : del.s3_delivery?.s3_bucket,
                        s3_file_prefix:
                          commonDeliveryFilePrefix?.split('/')[0] ===
                          OLD_DOWNLOAD_DELIVERY_FILE_PREFIX
                            ? renameToNewFormat(commonDeliveryFilePrefix)
                            : commonDeliveryFilePrefix,
                      },
                    }
                  : {}),
                ...(hasSnowflake
                  ? {
                      snowflake_delivery: {
                        ...del.snowflake_delivery,
                        snowflake_file_prefix: commonDeliveryFilePrefix,
                      },
                    }
                  : {}),
              });
              return cleanedDuplicateDeliverable;
            }) as DataBuilderDeliverable[]
          ),
          setInputRefs({
            company_reference:
              deliverableCompanySelection?.company_reference || '',
            pipeline_input: deliverableCompanySelection?.pipeline_input || '',
            company_sets:
              (deliverableCompanySelection?.company_sets as CompanySet[]) || [],
          }),
          setDuplicateDatasetNaming(savedDuplicateDatasetNaming),
          setLag_data(deliverable?.lag_data as boolean),
          setClient(deliverable?.client as string),
          setName(`${deliverable?.name}_copy`)
        );
        navigate(`/${PagePaths.DELIVERABLES}/${DeliverablePaths.DATASET}`);
      }}
    >
      Duplicate & Edit
    </MenuItem>
  );
};
