import { useUserTrialDetails } from '@revelio/auth';
import { D3ChartGeneratorLookup } from '@revelio/d3';
import { ChartConfigForPlot } from '@revelio/filtering';
import { plotConfigObject } from '../../../hooks/plot-info/plotInfo';
import { FiImage } from 'react-icons/fi';
import ActionMenuButton from '../action-menu-button/action-menu-button';
import DiagnosticsModal from '../diagnostics-modal/diagnostics-modal';
import PlotLinkModal from '../plot-link-modal/plot-link-modal';
import DownloadModal from '../download-modal/download-modal';
import PlotInfoComp from '../plot-info-comp/plot-info-comp';
import PlotModal from '../plot-modal/plot-modal';
import './default-card-modals.module.css';
import {
  BaseCardProps,
  DiagnosticsModalStateType,
  DownloadModalStateType,
  FullScreenStateType,
  PlotDimensionsStateType,
  PlotLinkModalStateType,
  PlotModalStateType,
} from '../../types';
import { isBoolean } from 'lodash';
import { CompositionResponse, Posting2dResponse } from '@revelio/data-access';
import { CombinedError } from 'urql';

export interface DefaultCardModalsProps extends BaseCardProps {
  typeAndProps?: ChartConfigForPlot;
  plotModalState: PlotModalStateType;
  plotDimensionsState: PlotDimensionsStateType;
  downloadModalState: DownloadModalStateType;
  diagnosticsModalState: DiagnosticsModalStateType;
  plotLinkModalState: PlotLinkModalStateType;
  fullScreenState: FullScreenStateType;
  topLeft: React.ReactNode | string;
  plotInfoBody: React.ReactElement | null;
  plotInfoConfig: plotConfigObject | null;
  includeDiagnosticsModal?: boolean;
  data?: (CompositionResponse | null)[] | (Posting2dResponse | null)[] | null;
  loading?: boolean;
  error?: CombinedError;
}

export function DefaultCardModals({
  plotConfig,
  cardConfig,
  downloadConfig,
  typeAndProps,
  plotModalState,
  plotDimensionsState,
  downloadModalState,
  diagnosticsModalState,
  plotLinkModalState,
  fullScreenState,
  topLeft,
  plotInfoBody,
  plotInfoConfig,
  data,
  loading,
  error,
}: DefaultCardModalsProps) {
  const { isTrialUser } = useUserTrialDetails();
  return (
    <>
      <PlotModal
        isOpen={plotModalState.isOpen}
        onOpen={plotModalState.onOpen}
        onClose={plotModalState.onClose}
        header={cardConfig.header as string}
        topLeft={
          <PlotInfoComp
            plotInfoBody={plotInfoBody}
            plotInfoConfig={plotInfoConfig}
            topLeft={topLeft}
          />
        }
        plotConfig={plotConfig}
        downloadConfig={downloadConfig}
        setPlotDimensions={plotDimensionsState.setPlotDimensions}
        additionalActionMenuItems={
          typeAndProps && typeAndProps?.chartType in D3ChartGeneratorLookup ? (
            <ActionMenuButton
              icon={FiImage}
              text="Download PNG"
              onClick={() => {
                fullScreenState.setIsFullScreen(true);
                downloadModalState.onDownloadOpen();
              }}
              disabled={isBoolean(isTrialUser) ? isTrialUser : true}
            />
          ) : undefined
        }
        data={data}
        loading={loading}
        error={error}
      />
      {cardConfig.includeDiagnosticsModal && (
        <DiagnosticsModal
          diagnosticsModalState={diagnosticsModalState}
          plotConfig={plotConfig}
        />
      )}
      <PlotLinkModal
        plotLinkModalState={plotLinkModalState}
        cardConfig={cardConfig}
        typeAndProps={typeAndProps}
        data$={plotConfig.dataProvider}
        data={data}
      />
      <DownloadModal
        header={cardConfig.header as string}
        isOpen={downloadModalState.isDownloadOpen}
        onOpen={downloadModalState.onDownloadOpen}
        onClose={downloadModalState.onDownloadClose}
        downloadDimension={plotDimensionsState.plotDimensions}
        typeAndProps={typeAndProps}
        isFullscreenMode={fullScreenState.isFullScreen}
        data$={plotConfig.dataProvider}
        data={data}
      />
    </>
  );
}

export default DefaultCardModals;
