import { graphql } from '@revelio/data-access';
import { get, isNil, mapKeys, round, uniqWith } from 'lodash';
import { GqlPlotName, IEntitiesTransformArgs } from './gql.models';

export enum Sentiment_GqlSchemaAttrs {
  OVERALL = 'overallRating',
  BUSINESS = 'businessOutlookRating',
  CAREER = 'careerOpportunitiesRating',
  COMPENSATION = 'compensationsBenefitsRating',
  WORKLIFE = 'workLifeBalanceRating',
  CULTURE = 'cultureValuesRating',
  LEADERSHIP = 'seniorLeadershipRating',
  DIVERSITY = 'diversityInclusionRating',
  RECOMMEND = 'recommendToAFriendRating',
}

export const Sentiment_Effects_SchemaAttrs = [
  ['careerAdvancementScore', 'Career'],
  ['companySizeScore', 'Company Size'],
  ['compensationScore', 'Compensation'],
  ['cultureScore', 'Culture'],
  ['coworkersScore', 'Coworkers'],
  ['diversityAndInclusionScore', 'D&I'],
  ['innovativeTechnologyScore', 'Tech'],
  ['managementScore', 'Management'],
  ['mentorshipScore', 'Mentorship'],
  ['onboardingScore', 'Onboarding'],
  ['perksScore', 'Perks'],
  ['remoteWorkScore', 'Remote Work'],
  ['workLifeBalanceScore', 'Work-Life'],
];

const _Sentiment_GqlPlotNameToAttributePathLookup: {
  [key in GqlPlotName]?: Sentiment_GqlSchemaAttrs;
} = {
  [GqlPlotName.OVERALL]: Sentiment_GqlSchemaAttrs.OVERALL,
  [GqlPlotName.BUSINESS]: Sentiment_GqlSchemaAttrs.BUSINESS,
  [GqlPlotName.CAREER]: Sentiment_GqlSchemaAttrs.CAREER,
  [GqlPlotName.COMPENSATION]: Sentiment_GqlSchemaAttrs.COMPENSATION,
  [GqlPlotName.WORKLIFE]: Sentiment_GqlSchemaAttrs.WORKLIFE,
  [GqlPlotName.CULTURE]: Sentiment_GqlSchemaAttrs.CULTURE,
  [GqlPlotName.LEADERSHIP]: Sentiment_GqlSchemaAttrs.LEADERSHIP,
  [GqlPlotName.DIVERSITY]: Sentiment_GqlSchemaAttrs.DIVERSITY,
  [GqlPlotName.RECOMMEND]: Sentiment_GqlSchemaAttrs.RECOMMEND,
};

export const Sentiment_GqlPlotNameToAttributePathLookup = {
  ..._Sentiment_GqlPlotNameToAttributePathLookup,
  ...mapKeys(
    _Sentiment_GqlPlotNameToAttributePathLookup,
    (v, key) => `${key}_overtime`
  ),
};

export function Sentiment_getGqlDataAttributePath(
  plotName: GqlPlotName
): string {
  if (!plotName) {
    return 'WTF_MATE';
  }

  const possibleAttrNameOrPaths =
    Sentiment_GqlPlotNameToAttributePathLookup[plotName];

  return possibleAttrNameOrPaths || plotName;
}

export function Sentiment1d_entitiesTransform({
  entities,
  filters,
  plotName,
}: IEntitiesTransformArgs) {
  const mappedDataAttrPath = Sentiment_getGqlDataAttributePath(
    plotName as GqlPlotName
  );
  const tFormedEntities = entities.map((ent: any) => {
    const { metadata, metrics } = ent;
    const shortName: string = get(metadata, 'shortName', '-');
    const [year, month] = shortName
      .split('-')
      .map((v) => (v ? Number.parseFloat(v) : v));
    const meta2d = /\d{4}-\d{2}/.test(shortName) ? { year, month } : {};
    const isEffects = mappedDataAttrPath == GqlPlotName.EFFECT;
    let _value;
    if (isEffects) {
      _value = Sentiment_Effects_SchemaAttrs.map(([attrPath, longName]) => {
        let _v = get(metrics, attrPath);
        _v = _v ? round(_v, 2) : null;
        return {
          longName,
          count: get(metrics, `${attrPath}Count`),
          value: _v,
        };
      });
    } else {
      _value = get(metrics, mappedDataAttrPath);
      _value = _value ? round(_value, 2) : null;
    }

    const fmEnt = {
      id: metadata.id,
      value: _value,
      metadata: {
        ...metadata,
        ...meta2d,
        count: get(metrics, `${mappedDataAttrPath}Count`),
      },
    };
    return fmEnt;
  });

  return tFormedEntities;
}

export function Sentiment2d_entitiesTransform({
  entities,
  filters,
  plotName,
}: IEntitiesTransformArgs) {
  const tFormedEntities = entities.map((ent: any) => {
    const metadata = get(ent, 'metadata');
    const category = get(ent, 'category');
    const fmEnt = {
      id: metadata.id,
      metadata: metadata,
      value: Sentiment1d_entitiesTransform({
        entities: category,
        plotName,
        filters,
      }),
    };
    return fmEnt;
  });
  return tFormedEntities;
}

export function SentimentReviews_entitiesTransform({
  entities,
  filters,
  plotName,
}: IEntitiesTransformArgs) {
  const tFormedEntities = entities.map((ent: any) => {
    let category = get(ent, 'category', []);
    if (isNil(category)) {
      category = [];
    }
    return uniqWith(
      category.map((cat: any) => {
        const word = get(cat, 'metadata.shortName');
        let count = get(cat, 'metrics.count', 1.0);
        count = Math.abs(round(count));
        return [word, count];
      }),
      (a: string[], b: string[]) => a[0] == b[0]
    );
  });
  return tFormedEntities;
}

export const SENTIMENT_GET_SNAPSHOT_DATA = graphql(`
  query SentimentSnapshotData($filters: SentimentFilters, $dim1: Dimension1) {
    sentiment1d: sentiment2d(filters: $filters, dim1: $dim1, dim2: month) {
      metadata {
        id
        shortName
        longName
      }
      metrics {
        overallRating
        overallRatingCount
        businessOutlookRating
        businessOutlookRatingCount
        careerOpportunitiesRating
        careerOpportunitiesRatingCount
        compensationsBenefitsRating
        compensationsBenefitsRatingCount
        workLifeBalanceRating
        workLifeBalanceRatingCount
        cultureValuesRating
        cultureValuesRatingCount
        seniorLeadershipRating
        seniorLeadershipRatingCount
        diversityInclusionRating
        diversityInclusionRatingCount
        recommendToAFriendRating
        recommendToAFriendRatingCount
        careerAdvancementScore
        careerAdvancementScoreCount
        companySizeScore
        companySizeScoreCount
        compensationScore
        compensationScoreCount
        cultureScore
        cultureScoreCount
        coworkersScore
        coworkersScoreCount
        diversityAndInclusionScore
        diversityAndInclusionScoreCount
        innovativeTechnologyScore
        innovativeTechnologyScoreCount
        managementScore
        managementScoreCount
        mentorshipScore
        mentorshipScoreCount
        onboardingScore
        onboardingScoreCount
        perksScore
        perksScoreCount
        remoteWorkScore
        remoteWorkScoreCount
        workLifeBalanceScore
        workLifeBalanceScoreCount
      }
    }
  }
`);

export const SENTIMENT_GET_OVERTIME_DATA = graphql(`
  query SentimentOvertimeData($filters: SentimentFilters, $dim1: Dimension1) {
    sentiment2d(filters: $filters, dim1: $dim1, dim2: month) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          id
          shortName
          longName
        }
        metrics {
          overallRating
          overallRatingCount
          businessOutlookRating
          businessOutlookRatingCount
          careerOpportunitiesRating
          careerOpportunitiesRatingCount
          compensationsBenefitsRating
          compensationsBenefitsRatingCount
          workLifeBalanceRating
          workLifeBalanceRatingCount
          cultureValuesRating
          cultureValuesRatingCount
          seniorLeadershipRating
          seniorLeadershipRatingCount
          diversityInclusionRating
          diversityInclusionRatingCount
          recommendToAFriendRating
          recommendToAFriendRatingCount
          careerAdvancementScore
          careerAdvancementScoreCount
          companySizeScore
          companySizeScoreCount
          compensationScore
          compensationScoreCount
          cultureScore
          cultureScoreCount
          coworkersScore
          coworkersScoreCount
          diversityAndInclusionScore
          diversityAndInclusionScoreCount
          innovativeTechnologyScore
          innovativeTechnologyScoreCount
          managementScore
          managementScoreCount
          mentorshipScore
          mentorshipScoreCount
          onboardingScore
          onboardingScoreCount
          perksScore
          perksScoreCount
          remoteWorkScore
          remoteWorkScoreCount
          workLifeBalanceScore
          workLifeBalanceScoreCount
        }
      }
    }
  }
`);

export const SENTIMENT_REVIEWS_POSITIVE = graphql(`
  query SentimentReviewsPositive(
    $filters: SentimentFilters
    $dim1: Dimension1
  ) {
    sentimentReviews(filters: $filters, dim1: $dim1, dim2: positive) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          shortName
        }
        metrics {
          count
        }
      }
    }
  }
`);

export const SENTIMENT_REVIEWS_NEGATIVE = graphql(`
  query SentimentReviewsNegative(
    $filters: SentimentFilters
    $dim1: Dimension1
  ) {
    sentimentReviews(filters: $filters, dim1: $dim1, dim2: negative) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          shortName
        }
        metrics {
          count
        }
      }
    }
  }
`);
