import { colors, KDEPlot } from '@revelio/d3';
import { Card } from '@revelio/layout';
import { Box, Text } from '@chakra-ui/layout';
import { getWeightedAverageSalariesAndCDFs } from './getWeightedAverageSalariesAndCDFs';
import { SalaryDistributionProps } from './types';
import { useMemo, useEffect } from 'react';

export const SalaryDistribution = ({
  salaryData,
  competitors,
  primaryCompanyRCID,
  primaryCompanyName,
  setLoading,
}: SalaryDistributionProps) => {
  const { aggregatedSalaryValues, aggregatedCumulativePercentage } = useMemo(
    () =>
      getWeightedAverageSalariesAndCDFs(
        competitors,
        salaryData?.value?.filter(
          (comp) => comp?.metadata?.longName !== primaryCompanyRCID
        )
      ),
    [competitors, salaryData, primaryCompanyRCID]
  );

  useEffect(() => {
    if (
      aggregatedSalaryValues?.every((value) => value === 0) &&
      aggregatedCumulativePercentage?.every((value) => value === 0)
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [aggregatedCumulativePercentage, aggregatedSalaryValues, setLoading]);

  const primaryCompanyData = salaryData?.value?.find(
    (comp) => comp?.metadata?.longName === primaryCompanyRCID
  );

  const weightedCompetitors = {
    value: aggregatedSalaryValues,
    metadata: {
      salaries: primaryCompanyData?.metadata?.salaries,
      cdf: aggregatedCumulativePercentage,
    },
  };

  const data = [
    {
      ...primaryCompanyData,
      metadata: {
        ...primaryCompanyData?.metadata,
        longName: primaryCompanyName,
      },
    },
    {
      ...weightedCompetitors,
      metadata: {
        ...weightedCompetitors?.metadata,
        longName: 'Competitors',
      },
    },
  ];

  const primaryCompanyColor = colors[1];
  const competitorColor = colors[0];
  return (
    <Card>
      <Box height="100%" py={4}>
        <Text fontWeight={600}>Salary Distribution</Text>
        {data?.length > 0 && (
          <KDEPlot
            data={data}
            ttMainFormat="$,"
            ttType="single"
            lineColor={[primaryCompanyColor, competitorColor]}
            gradient={[`${primaryCompanyColor}35`, `${competitorColor}35`]}
          />
        )}
      </Box>
    </Card>
  );
};
