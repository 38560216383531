import { Box, Grid, GridItem, Text, Flex } from '@chakra-ui/react';
import { PageTitles, AddEntityButtonText, PrimaryFilters } from '@revelio/core';
import {
  AddEntityButton,
  COMPOSITION_GET_DATA,
  FilterChipsContainer,
  FilterItem,
  GEOGRAPHY_GRANULARITY_FILTERS,
  PrimaryFilterLimits,
  SHARED_SET_ENTITY_LIMIT,
  SelectionCategories,
  createSelectableFiltersMap,
  useActiveFiltersState,
  useViewFilters,
} from '@revelio/filtering';
import { flatten } from 'lodash';
import { useMemo } from 'react';
import DashboardPage from '../../DashboardPage';
import { useQuery } from 'urql';
import { Dimension1 } from '@revelio/data-access';
import { GeographySummaryTopCompanies } from './top-companies/top-companies';
import { GeographySummaryTopSkills } from './top-skills/top-skills';

export const GeographySummary = () => {
  const primaryViewFilters = useMemo(
    () => [
      {
        filters: GEOGRAPHY_GRANULARITY_FILTERS,
        isNested: true,
        limit: 1,
      },
    ],
    []
  );

  const primaryFilters = useMemo(
    () => flatten(createSelectableFiltersMap(primaryViewFilters)),
    [primaryViewFilters]
  );

  useViewFilters([...primaryFilters]); //set limit here?

  const activeFilters = useActiveFiltersState();
  const selectedGeographyId = (
    activeFilters.find((filter) =>
      [
        SelectionCategories.REGION,
        SelectionCategories.COUNTRY,
        SelectionCategories.METRO_AREA,
      ].includes(filter.id as SelectionCategories)
    )?.value as FilterItem[]
  )?.[0]?.id;

  const [{ data, fetching: compositionLoading }] = useQuery({
    query: COMPOSITION_GET_DATA,
    variables: {
      dim1: Dimension1.Geography,
      filters: {
        region: [selectedGeographyId],
        start_date: '2024-04',
        end_date: '2024-04', //??
        category_sub_filter: {
          skill: ['1'], // setting to 1 since unused but if left empty, api returns all 75 skills
        },
      },
    },
    pause: !selectedGeographyId,
  });

  const anyRequestsLoading = compositionLoading;

  return (
    <DashboardPage
      title={[PageTitles.GEOGRAPHY, PageTitles.SUMMARY]}
      hideSelectionsMargins
      loading={anyRequestsLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="filterChip"
            showColors
            isPrimaryChip
            useChipsSkeleton={false}
            min={1}
            addButton={
              <AddEntityButton
                entities={primaryViewFilters}
                entityName={AddEntityButtonText[PrimaryFilters.GEOGRAPHY]}
                buttonText={AddEntityButtonText[PrimaryFilters.GEOGRAPHY]}
                activeLimit={PrimaryFilterLimits.GEOGRAPHY_SUMMARY}
                limit={SHARED_SET_ENTITY_LIMIT}
                required={1}
                // trialNoResultsMessage={trialNoResultsMessage}
              />
            }
          />
        </Flex>
      }
    >
      {/* Main Content: Two Columns */}
      <Grid templateColumns="2fr 1fr" gap={4}>
        {/* Left Column: Metrics and Map */}
        <GridItem colSpan={1}>
          {/* Metrics Row */}
          <Grid templateColumns="repeat(4, 1fr)" gap={4} mb={4}>
            <GridItem>
              <Box bg="gray.100" p={4} borderRadius="md" height="100%">
                <Text fontWeight="bold">Supply/Demand</Text>
                {/* Insert hot/cold component  */}
              </Box>
            </GridItem>
            <GridItem>
              <Box bg="gray.100" p={4} borderRadius="md" height="100%">
                <Text fontWeight="bold">Wage Pressure</Text>
                {/* Insert hot/cold component  */}
              </Box>
            </GridItem>
            <GridItem>
              <Box bg="gray.100" p={4} borderRadius="md" height="100%">
                <Text fontWeight="bold">Time to Fill</Text>
                {/* Insert hot/cold component  */}
              </Box>
            </GridItem>
            <GridItem>
              <Box bg="gray.100" p={4} borderRadius="md" height="100%">
                <Text fontWeight="bold">Market Tightness</Text>
                {/* Insert hot/cold component  */}
              </Box>
            </GridItem>
          </Grid>

          {/* Map Row */}
          <Box bg="gray.100" p={4} borderRadius="md" height="100%">
            <Text fontWeight="bold">Map</Text>
            {/* Insert map component  */}
          </Box>
        </GridItem>

        {/* Right Column: Top Companies and Top Skills */}
        <GridItem colSpan={1}>
          <Box p={4} borderRadius="md" height="100%" mb={4}>
            <GeographySummaryTopCompanies data={data} />
          </Box>
          <Box p={4} borderRadius="md" height="100%">
            <GeographySummaryTopSkills data={data} />
          </Box>
        </GridItem>
      </Grid>
    </DashboardPage>
  );
};
