import { Box, Flex, Text } from '@chakra-ui/layout';
import { Card } from '@chakra-ui/react';
import { BarChartHorizontal } from '@revelio/d3';
import { CompositionDataQuery } from '@revelio/data-access';
import { Select } from 'chakra-react-select';
import { useMemo, useState } from 'react';
import {
  GeographyBarChartsData,
  GeographySummaryMetric,
  SingleOption,
} from '../../types';
import { getGeographyBarChartProps } from '../utils/get-geography-bar-chart-props';
import { getGeographyCompositionData } from '../utils/get-geography-composition-data';

interface GeographySummaryTopCompaniesProps {
  data: CompositionDataQuery | undefined;
}

export const GeographySummaryTopCompanies = ({
  data,
}: GeographySummaryTopCompaniesProps) => {
  const compositionData: GeographyBarChartsData = useMemo(
    () => getGeographyCompositionData(data),
    [data]
  );

  const [selectedChart, setSelectedChart] = useState<SingleOption>({
    value: 'industries', //companies
    label: 'Industries', //Companies
  });

  const chartProps = useMemo(
    () =>
      getGeographyBarChartProps(
        compositionData,
        selectedChart,
        'top-companies'
      ),
    [compositionData, selectedChart]
  );

  return (
    <Card height="100%">
      <Box height="100%" p={4}>
        <Flex justifyContent="space-between">
          <Text fontWeight={600}>Top {selectedChart?.label}</Text>
          <Select
            value={selectedChart}
            onChange={(value) => setSelectedChart(value)}
            options={[
              // { value: GeographySummaryMetric.Companies, label: 'Companies' },
              { value: GeographySummaryMetric.Industries, label: 'Industries' },
            ]}
            chakraStyles={{
              control: (prev) => ({
                ...prev,
                backgroundColor: '#F1F5F9',
              }),
            }}
          />
        </Flex>

        <Box height="85%">
          <BarChartHorizontal {...chartProps} />
        </Box>
      </Box>
    </Card>
  );
};
