import { Stack } from '@chakra-ui/react';
import { SubFilter, SubFilterProps } from '@revelio/filtering';
import { Card, Plot, PlotData } from '@revelio/layout';
import { useObservable } from 'react-use';
import { isObservable, of } from 'rxjs';
import PlotInfoComp from '../plot-info-comp/plot-info-comp';
import DefaultCardActionMenu from '../default-card-action-menu/default-card-action-menu';
import DefaultCardModals from '../default-card-modals/default-card-modals';
import { useDefaultCardHelperState } from '../../../hooks/default-card/useDefaultCardHelperState';
import { BaseCardProps } from '../../types';
import {
  usePlotInfo,
  viewflowReturns,
} from '../../../hooks/plot-info/usePlotInfo';
import PlotDataLagWarning from '../plot-data-lagged/plotDataLagWarning';
import { isUndefined } from 'lodash';
import { useMemo } from 'react';
import { CombinedError } from 'urql';

export interface DefaultCardProps extends BaseCardProps {
  subfilter?: SubFilterProps;
  topLeft?: React.ReactNode | string;
  customPlotInfo?: viewflowReturns;
  disablePlotInfo?: boolean;
  topRight?: React.ReactNode | string;
  disableTopRightCardActionMenu?: boolean;
  style?: React.CSSProperties;
  p?: string;
  includeDiagnosticsModal?: boolean;
  isSubCard?: boolean;
  alternateMainContent?: React.ReactNode | string;
  alternateMainContentDisplayStateForPrimaryContent?: boolean | undefined;
  data?: PlotData;
  loading?: boolean;
  error?: CombinedError;
}

export function DefaultCard({
  cardConfig,
  customPlotInfo = { plotInfoBody: null, plotInfoConfig: null },
  downloadConfig,
  plotConfig,
  alternateMainContent,
  alternateMainContentDisplayStateForPrimaryContent,
  subfilter,
  topLeft,
  disablePlotInfo = false,
  topRight,
  disableTopRightCardActionMenu = false,
  style,
  p,
  isSubCard = false,
  data,
  loading,
  error,
}: DefaultCardProps) {
  const {
    plotModalState,
    diagnosticsModalState,
    plotLinkModalState,
    downloadModalState,
    fullScreenState,
    plotDimensionsState,
  } = useDefaultCardHelperState();

  const typeAndProps = useObservable(
    isObservable(plotConfig.chartTypeAndProps)
      ? plotConfig.chartTypeAndProps
      : of(plotConfig.chartTypeAndProps)
  );

  const {
    plotInfoBody: customPlotInfoBody,
    plotInfoConfig: customPlotInfoConfig,
  } = customPlotInfo;
  const { plotInfoBody, plotInfoConfig } = usePlotInfo({
    view: cardConfig.view as string,
    endpointSegment: cardConfig.endpointSegment as string,
    viewType: cardConfig.viewType,
  });

  const firstMainContent = useMemo(() => {
    const { sx, ..._plotConfig } = plotConfig;
    return isUndefined(alternateMainContentDisplayStateForPrimaryContent)
      ? alternateMainContent || (
          <Plot
            disableD3={false}
            sx={{
              ...sx,
              visibility: alternateMainContentDisplayStateForPrimaryContent
                ? 'hidden'
                : 'visible',
            }}
            {..._plotConfig}
            data={data}
            loading={loading}
            error={error}
          />
        )
      : alternateMainContent;
  }, [
    alternateMainContentDisplayStateForPrimaryContent,
    alternateMainContent,
    plotConfig,
    data,
    loading,
    error,
  ]);

  const secondMainContent = useMemo(() => {
    const { sx, ..._plotConfig } = plotConfig;
    return (
      !isUndefined(alternateMainContentDisplayStateForPrimaryContent) && (
        <Plot
          disableD3={false}
          sx={{
            ...sx,
            visibility: alternateMainContentDisplayStateForPrimaryContent
              ? 'visible'
              : 'hidden',
          }}
          {..._plotConfig}
        />
      )
    );
  }, [alternateMainContentDisplayStateForPrimaryContent, plotConfig]);

  return (
    <Card
      style={style}
      header={cardConfig.header}
      topLeft={
        <PlotInfoComp
          plotInfoBody={customPlotInfoBody || plotInfoBody}
          plotInfoConfig={customPlotInfoConfig || plotInfoConfig}
          topLeft={topLeft}
          disableInfoPopup={disablePlotInfo}
        />
      }
      topRight={
        <Stack spacing={0} direction="row" align="center">
          <PlotDataLagWarning />
          {topRight}
          <DefaultCardModals
            plotConfig={plotConfig}
            cardConfig={cardConfig}
            downloadConfig={downloadConfig}
            typeAndProps={typeAndProps}
            plotModalState={plotModalState}
            plotDimensionsState={plotDimensionsState}
            downloadModalState={downloadModalState}
            fullScreenState={fullScreenState}
            plotInfoBody={plotInfoBody}
            plotInfoConfig={plotInfoConfig}
            topLeft={topLeft}
            diagnosticsModalState={diagnosticsModalState}
            plotLinkModalState={plotLinkModalState}
            data={data}
            loading={loading}
            error={error}
          />
          {subfilter && (
            <SubFilter
              hideTriggerDefault={subfilter.hideTriggerDefault || true}
              {...subfilter}
            />
          )}
          {!disableTopRightCardActionMenu && (
            <DefaultCardActionMenu
              downloadConfig={downloadConfig}
              plotConfig={plotConfig}
              typeAndProps={typeAndProps}
              plotModalState={plotModalState}
              downloadModalState={downloadModalState}
              fullScreenState={fullScreenState}
              cardConfig={cardConfig}
              diagnosticsModalState={diagnosticsModalState}
              plotLinkModalState={plotLinkModalState}
            />
          )}
        </Stack>
      }
      slim={cardConfig.slim}
      switch={cardConfig.switch}
      footer={cardConfig.footer}
      p={p || undefined}
      isSubCard={isSubCard}
    >
      {firstMainContent}
      {secondMainContent}
    </Card>
  );
}

export default DefaultCard;
