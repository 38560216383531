import {
  FilterChips,
  FilterContainer,
  filterKeywordSelection,
  FilterMenu,
  filterTreeItemData,
  RequestMethod,
  useTalentDiscoveryFilterContext,
} from '@revelio/filtering';
import { DownloadButton } from '@revelio/layout';
import { Views } from '@revelio/core';

import { TDV2FilterNames, TDV2MenuConfig } from './talent-discovery.config';
import { fetchSubsidiaries } from '../deliverables/company-selection/subsidiary-mapping/subsidiary-mapping.api';
import { useUserTrialDetails } from '@revelio/auth';

export const TalentDiscoveryFilterMenu = () => {
  const talentDiscoveryFilterApi = useTalentDiscoveryFilterContext();
  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });
  return (
    <FilterContainer
      flexDirection="row"
      alignItems="flex-start"
      justifyContent={'space-between'}
    >
      <FilterChips
        filterNames={TDV2FilterNames}
        TDMenuConfig={TDV2MenuConfig}
        view={Views.TALENT_DISCOVERY}
        variant="filterChip"
        useChipsSkeleton={false}
        talentDiscoveryFilterApi={talentDiscoveryFilterApi}
        filterTreeItemData={filterTreeItemData}
        addButton={
          <FilterMenu
            title="Filter"
            filters={TDV2MenuConfig}
            view={Views.TALENT_DISCOVERY}
            selectMenuOpenDefault
            fetchSubsidiaries={fetchSubsidiaries}
            selectListHeight={262.75}
            addSkillFilterChip={
              talentDiscoveryFilterApi?.skillFilterApi?.addFilterChip
            }
            filterTreeItemData={filterTreeItemData({ clearAll: true })}
            filterKeywordSelection={filterKeywordSelection({
              clearAll: true,
            })}
          />
        }
      />

      <DownloadButton
        disabled={isTrialUser}
        endpoint="/api/talent-discovery/downloads"
        requestMethod={RequestMethod.POST}
        view={Views.TALENT_DISCOVERY}
        isGoRequest={true}
        useLoading={true}
        loadingText="Preparing Download..."
      />
    </FilterContainer>
  );
};
