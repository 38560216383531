import { flatten } from 'lodash';
import { useMemo, useState } from 'react';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/layout';

import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  Views,
} from '@revelio/core';
import {
  AddEntityButton,
  COMPOSITION_GET_DATA,
  createSelectableFiltersMap,
  DateRangeFormattedValues,
  DefaultDates,
  FilterChips,
  FilterChipsContainer,
  FilterContainer,
  FilterItem,
  FilterMenu,
  FilterMenuLimits,
  FilterSets,
  INDUSTRY_AND_COMPANY_FILTERS,
  LocalSelectionCategories,
  PrimaryFilterLimits,
  ROLE_GRANULARITY_FILTERS,
  SelectionCategories,
  SHARED_SET_ENTITY_LIMIT,
  SKILL_GRANULARITY_FILTERS,
  useActiveFiltersState,
  useDefaultLastMonth,
  usePrimaryFilter,
  useSelectionLists,
  useStoredFilterSet,
  useSyncFiltersToSearchParams,
  useViewFilterDefaults,
  useViewFilters,
  ViewTypes,
} from '@revelio/filtering';

import DashboardPage from '../../DashboardPage';
import { SalaryDistribution } from './salary-distribution/salary-distribution';
import { CompanySummaryOverview } from './company-summary-overview';
import { useQuery } from 'urql';
import { GET_COMPANY_ENTITY_DATA } from '@revelio/filtering';
import { useGetKdePlotData } from './salary-distribution/useGetKdePlotData';
import { Dimension1 } from '@revelio/data-access';
import { CompetitorBarCharts } from './competitor-bar-charts/competitor-bar-charts';
import { CompanyMap } from './map/company-map';
import { useGetCompanyMapData } from './map/useGetCompanyMapData';

export const CompanySummary = () => {
  const primaryViewFilters = useMemo(
    () => [
      {
        filters: INDUSTRY_AND_COMPANY_FILTERS,
        isNested: true,
        limit: 1,
      },
    ],
    []
  );

  const primaryFilters = useMemo(
    () => flatten(createSelectableFiltersMap(primaryViewFilters)),
    [primaryViewFilters]
  );

  usePrimaryFilter(PrimaryFilters.COMPANY);

  useStoredFilterSet({
    sharedSetId: FilterSets.COMPANY_SUMMARY,
    tab: ViewTypes.COMPANY,
    primaryEntitiesSync: true,
    limit: 1,
    defaultLimit: 1,
  });

  useViewFilters([...primaryFilters, SelectionCategories.DATE_RANGE]);

  useSelectionLists([
    SelectionCategories.COMPANY,
    SelectionCategories.INDUSTRY,
  ]);

  const viewFilterDefaultArgs = {
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    presetView: FilterSets.COMPANY_SUMMARY,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [
      LocalSelectionCategories.DATA_METRIC,
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    limit: PrimaryFilterLimits.ENTITY_SUMMARY,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);

  useSyncFiltersToSearchParams({
    primaryFilters,
    syncToPrimaryEntities: true,
  });

  const activeFilters = useActiveFiltersState();
  const selectedCompanyRCID = parseInt(
    (
      activeFilters.find((filter) => filter.id === SelectionCategories.COMPANY)
        ?.value as FilterItem[]
    )?.[0]?.id as string,
    10
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ data: entityData, fetching: primaryCompanyLoading }] = useQuery({
    query: GET_COMPANY_ENTITY_DATA,
    variables: {
      filters: {
        company: [selectedCompanyRCID],
      },
    },
    pause: !selectedCompanyRCID,
    // pause: true,
  });

  // const entityData = fakeEntityData;

  const competitors = entityData?.entity?.competitors;
  const primaryAndCompetitorRCIDs = useMemo(() => {
    const competitorIds = competitors
      ?.filter((competitor) => competitor?.metadata?.id)
      .map((competitor) => competitor?.metadata?.id as number);

    return competitorIds?.length ? [selectedCompanyRCID, ...competitorIds] : [];
  }, [competitors, selectedCompanyRCID]);

  const { kdeSalaryData, loading: kdePlotsLoading } = useGetKdePlotData(
    primaryAndCompetitorRCIDs
  );

  useDefaultLastMonth({
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
  });

  const dates = activeFilters.find(
    (filter) => filter.id === SelectionCategories.DATE_RANGE
  )?.value as DateRangeFormattedValues;

  const [{ data, fetching: compositionLoading }] = useQuery({
    query: COMPOSITION_GET_DATA,
    variables: {
      dim1: Dimension1.Company,
      filters: {
        company: primaryAndCompetitorRCIDs,
        start_date: dates?.startDate,
        end_date: dates?.endDate,
        category_sub_filter: {
          skill: ['1'], // setting to 1 since unused but if left empty, api returns all 75 skills
        },
      },
    },
    pause: !primaryAndCompetitorRCIDs?.length || !dates,
  });

  const {
    data: talentDiscoveryDataQuery,
    loading: talentDiscoveryDataLoading,
  } = useGetCompanyMapData();

  const [salaryDisributionLoading, setSalaryDistributionLoading] =
    useState(false);
  const anyRequestsLoading = useMemo(
    () =>
      kdePlotsLoading ||
      primaryCompanyLoading ||
      compositionLoading ||
      salaryDisributionLoading ||
      talentDiscoveryDataLoading,
    [
      kdePlotsLoading,
      primaryCompanyLoading,
      compositionLoading,
      salaryDisributionLoading,
      talentDiscoveryDataLoading,
    ]
  );

  // TODO figure out which filters to allow
  const selectableFilters = [
    SelectionCategories.HIGHEST_DEGREE,
    SelectionCategories.ETHNICITY,
    SelectionCategories.GENDER,
    {
      filters: [
        SelectionCategories.REGION,
        SelectionCategories.COUNTRY,
        SelectionCategories.METRO_AREA,
      ],
      isNested: true,
      limit: 10,
    },
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.DATE_RANGE,
  ];

  return (
    <DashboardPage
      title={[PageTitles.COMPANY, PageTitles.SUMMARY]}
      hideSelectionsMargins
      loading={anyRequestsLoading}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            showColors={false}
            isPrimaryChip
            limit={PrimaryFilterLimits.COMPANY_SUMMARY}
            useChipsSkeleton={false}
            min={1}
            addButton={
              <AddEntityButton
                entities={primaryViewFilters}
                entityName={AddEntityButtonText[PrimaryFilters.COMPANY]}
                buttonText={AddEntityButtonText[PrimaryFilters.COMPANY]}
                disableParentSelect
                isParentCheckboxHidden
                activeLimit={PrimaryFilterLimits.COMPANY_SUMMARY}
                limit={SHARED_SET_ENTITY_LIMIT}
                required={1}
                // trialNoResultsMessage={trialNoResultsMessage}
              />
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent={'space-between'}
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={selectableFilters}
            variant="filterChip"
            limit={FilterMenuLimits.ENTITY_SUMMARY}
            showGranularity={true}
            viewType={ViewTypes.COMPANY}
            addButton={
              <FilterMenu
                title="Filter"
                filters={[
                  ...selectableFilters,
                  // SelectionCategories.SAVED_FILTER_SET,
                ]}
                selectMenuOpenDefault
                limit={FilterMenuLimits.ENTITY_SUMMARY}
                showFilterSetSaveMenu={false}
                viewIdForDefault={`${Views.ENTITY_SUMMARY}_${ViewTypes.COMPANY}`}
              />
            }
          />
        </Flex>
      </FilterContainer>
      <Grid
        templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
        gridTemplateRows="1fr 1.5fr"
        gap={4}
        height="100%"
      >
        {/* Row 1: Three equal items */}
        <GridItem>
          <CompanySummaryOverview
            company={entityData?.entity?.summary?.name}
            description={entityData?.entity?.summary?.description}
            founded={entityData?.entity?.summary?.founded}
            headquarters={entityData?.entity?.summary?.headquarters}
          />
        </GridItem>
        <GridItem>
          <CompetitorBarCharts data={data} primaryRCID={selectedCompanyRCID} />
        </GridItem>
        <GridItem>
          <Box bg="gray.100" borderRadius="md">
            <Text>Headcount & Growth</Text>
            {/* Add content here */}
          </Box>
        </GridItem>

        {/* Row 2: 2/3 and 1/3 layout */}
        <GridItem colSpan={{ base: 1, md: 2 }}>
          <CompanyMap
            mapData={
              talentDiscoveryDataQuery?.talent_discovery_search_v1_5?.map_data
            }
          />
        </GridItem>

        <GridItem colSpan={1}>
          <SalaryDistribution
            salaryData={kdeSalaryData}
            primaryCompanyRCID={selectedCompanyRCID}
            competitors={competitors}
            primaryCompanyName={entityData?.entity?.summary?.name}
            setLoading={setSalaryDistributionLoading}
          />
        </GridItem>
      </Grid>
    </DashboardPage>
  );
};
