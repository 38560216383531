import {
  TalentDiscoveryV1_5Filter,
  TalentDiscoveryV1_5FilterWithTag,
  TalentDiscoveryV1_5Tags,
} from '@revelio/data-access';
import { SkillFilterState, SkillLevel } from './use-skill-filter-api';
import { isNestedArray } from '../../utils/is-nested-array';

const convertSkillLevelToSkillDim = (
  skillLevel: SkillLevel
): TalentDiscoveryV1_5Tags => {
  switch (skillLevel) {
    case 'skill_k75':
      return TalentDiscoveryV1_5Tags.SkillsK75;
    case 'skill_k700':
      return TalentDiscoveryV1_5Tags.SkillsK700;
    case 'skill_k3000':
      return TalentDiscoveryV1_5Tags.SkillsK3000;
  }
};

export const talentDiscoveryFilterTransformation = ({
  skillFilters,
  filters,
}: {
  skillFilters: SkillFilterState;
  filters: TalentDiscoveryV1_5Filter;
}): TalentDiscoveryV1_5Filter => {
  const { free_texts, ...restFilters } = filters;

  const skills: TalentDiscoveryV1_5FilterWithTag[][] = skillFilters
    .map((skillChip) =>
      skillChip.map(
        (skillFilter): TalentDiscoveryV1_5FilterWithTag => ({
          id: skillFilter.id,
          dim: convertSkillLevelToSkillDim(skillFilter.skillLevel),
        })
      )
    )
    .filter((skillChip) => skillChip.length > 0);

  const tdV1_5Filters: TalentDiscoveryV1_5Filter = {
    ...restFilters,
    // Guarantees keywords are a nested array to match BE schema.
    ...((free_texts?.length || 0) > 0 && {
      free_texts: isNestedArray(free_texts)
        ? free_texts
        : ([free_texts] as any),
    }),
    ...(skills.length > 0 && { skills }),
  };

  return tdV1_5Filters as TalentDiscoveryV1_5Filter;
};
