import {
  Flex,
  FormControl,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
} from '@chakra-ui/react';
import { StringOrNumber } from '@chakra-ui/utils';
import { useEffect$ } from '@ngneat/react-rxjs';
import {
  ActionModal,
  ActionModalControlPanel,
  downloadFile,
} from '@revelio/core';
import { API_ROOT, SelectionCategories } from '@revelio/filtering';
import { DiagnosticsModalStateType, SelectOption } from '../../types';
import { queryEndpoint } from '@revelio/filtering';
import { get, isNumber } from 'lodash';
import { useState } from 'react';
import { finalize, forkJoin, map, of } from 'rxjs';
import CheckList from '../check-list/check-list';
import './diagnostics-modal.module.css';
import { PlotProps } from '@revelio/layout';

/* eslint-disable-next-line */
export interface DiagnosticsModalProps {
  plotConfig: PlotProps;
  diagnosticsModalState: DiagnosticsModalStateType;
}

export function DiagnosticsModal({
  plotConfig,
  diagnosticsModalState,
}: DiagnosticsModalProps) {
  const { isDiagnosticsOpen: isOpen, onDiagnosticsClose: onClose } =
    diagnosticsModalState;

  const BASE_PATH = 'diagnostics';

  const [value, setValue] = useState<StringOrNumber[]>([]);

  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  useEffect$(() => {
    const dataStream = plotConfig?.dataProvider?.asObservable() || of([]);

    return dataStream.pipe(
      map((d) => {
        const isLoading = get(d, 'loading');

        if (!isLoading && Array.isArray(d)) {
          const mapped = d
            .filter((item) => {
              // NOTE: Assumes string id is of format [column_name]__[numeric_id]

              const itemType = get(item, 'metadata.__typename');
              const [columnName] = itemType || item.id.split('__');

              return columnName === SelectionCategories.COMPANY;
            })
            .map((item) => {
              return {
                id: item?.id,
                label: item?.metadata?.shortName,
                value: item?.metadata,
              };
            });

          setSelectOptions(mapped);
        }
      })
    );
  });

  const parseIds = (id: string | number) => {
    if (isNumber(id) || Number.isFinite(+id)) {
      return String(id);
    }

    // NOTE: Assumes string id is of format [column_name]__[numeric_id]
    const [, numericId] = id.split('__');

    return numericId;
  };

  const generateDiagnosticQueries = (values: StringOrNumber[]) => {
    return values.map((id) => {
      const numericId = parseIds(id);

      const endpoint = `${API_ROOT}/${BASE_PATH}/${numericId}`;
      const requestURL = new URL(endpoint);

      return queryEndpoint({
        url: requestURL,
        requestHash: endpoint,
        includeInGlobalLoader: false,
        catchErrorBool: true,
      }).pipe(
        map((res) => {
          if (!res.error && !res.loading) {
            if (res) {
              downloadFile(res);
            }
          }
        })
      );
    });
  };

  const handleSubmit = () => {
    try {
      setIsDownloading(true);

      const sources = generateDiagnosticQueries(value);

      forkJoin(sources)
        .pipe(
          finalize(() => {
            setTimeout(() => {
              setIsDownloading(false);
            }, 500);
          })
        )
        .subscribe({
          error: (e) => console.error('Download Error:', e),
        });
    } catch (err) {
      setIsDownloading(false);
      console.error('Download Error:', err);
    }
  };

  return (
    <ActionModal
      header="Headcount diagnostic"
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        setValue([]);
      }}
    >
      <ModalBody>
        <Flex direction="column" rowGap="1rem">
          <Text fontSize="sm">
            Select a company below to download a diagnostic document outlining
            the underlying headcounts.
          </Text>
          <FormControl isRequired>
            <Stack pt={1}>
              <CheckList
                value={value}
                setValue={setValue}
                selectOptions={selectOptions}
              />
            </Stack>
          </FormControl>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <ActionModalControlPanel
          submitIsLoading={isDownloading}
          submitIsDisabled={value.length < 1}
          onClose={() => {
            onClose?.();
          }}
          onSubmit={handleSubmit}
          onReset={() => {
            setValue([]);
          }}
        />
      </ModalFooter>
    </ActionModal>
  );
}

export default DiagnosticsModal;
