import { ControllerRenderProps, FieldValues } from 'react-hook-form';
import { ChakraStylesConfig, GroupBase, Select } from 'chakra-react-select';
import { getSelectFieldValue } from './dataset-conditions-form-helpers';
import { noop } from 'lodash';

export const getDefaultChakraStyles: ({
  width,
  fontSize,
}: {
  width: string;
  fontSize?: string;
}) => ChakraStylesConfig = ({ width, fontSize = '11px' }) => ({
  control: (provided) => ({
    ...provided,
    background: 'white',
    width,
  }),
  menu: (provided) => ({
    ...provided,
    width,
  }),
  option: (provided) => ({
    ...provided,
    fontSize,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize,
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize,
  }),
});

interface ConditionSelectProps<FormType extends FieldValues, OptionType> {
  options: { label: string; value: OptionType }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: ControllerRenderProps<FormType, any>; // annoyingly can't type the field name prop as it has to be a string defined inline
  isDisabled?: boolean;
  onChange?: (value: OptionType | undefined) => void;
  chakraStyles?: ChakraStylesConfig<
    { label: string; value: OptionType },
    false,
    GroupBase<{ label: string; value: OptionType }>
  >;
}
export function ConditionSelect<
  FormType extends FieldValues,
  OptionType extends string
>({
  options,
  field,
  isDisabled,
  onChange = noop,
  chakraStyles,
}: ConditionSelectProps<FormType, OptionType>) {
  return (
    <Select<
      { label: string; value: OptionType },
      false,
      GroupBase<{ label: string; value: OptionType }>
    >
      {...field}
      maxMenuHeight={400}
      isDisabled={isDisabled}
      value={getSelectFieldValue<OptionType>(
        field.value as OptionType,
        options
      )}
      onChange={(selectOption) => {
        onChange(selectOption?.value);
        field.onChange(selectOption?.value);
      }}
      variant="outline"
      useBasicStyles={true}
      options={options}
      chakraStyles={chakraStyles}
    />
  );
}
