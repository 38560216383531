import { Box } from '@chakra-ui/layout';
import { TalentDiscoveryMapV2, PopupDataKey } from '@revelio/d3';
import { TalentDiscoveryDataQuery } from '@revelio/data-access';
import { Card } from '@revelio/layout';

const popupDataKeys = [
  'headcount',
  'postings',
  'timeToFill',
  'salaryDist',
] as PopupDataKey[];

export const CompanyMap = ({
  mapData,
}: {
  mapData: TalentDiscoveryDataQuery['talent_discovery_search_v1_5']['map_data'];
}) => {
  return (
    <Card>
      <Box p={2} height="100%">
        <TalentDiscoveryMapV2
          data={{
            data: mapData || [],
            popupDataKeys,
          }}
        />
      </Box>
    </Card>
  );
};
