import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import theme from '@chakra-ui/theme';
import { GetEntityDataQuery } from '@revelio/data-access';
import { Card } from '@revelio/layout';

type EntitySummary = NonNullable<
  NonNullable<GetEntityDataQuery['entity']>['summary']
>;

interface CompanySummaryOverviewProps {
  company: EntitySummary['name'];
  description: EntitySummary['description'];
  founded: EntitySummary['founded'];
  headquarters: EntitySummary['headquarters'];
}

export const CompanySummaryOverview = ({
  company,
  description,
  founded,
  headquarters,
}: CompanySummaryOverviewProps) => {
  return (
    <Card>
      <Box p={2} height="100%">
        <Text fontWeight={600} fontSize={14} mb={2}>
          {company}
        </Text>
        <Text fontSize={14} height="70%" overflowY="scroll">
          {description}
        </Text>
        <Divider my={4} />
        <Flex>
          <Flex
            pr={8}
            mr={8}
            borderRight={`1px solid ${theme.colors.gray[200]}`}
          >
            <Text fontSize={14} fontWeight={600} mr={1}>
              Founded:
            </Text>
            <Text fontSize={14}>{founded}</Text>
          </Flex>
          <Text fontSize={14} fontWeight={600} mr={1}>
            Headquarters:
          </Text>
          <Text fontSize={14}>{headquarters}</Text>
        </Flex>
      </Box>
    </Card>
  );
};
