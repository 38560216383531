import { PageTitles, PrimaryFilters } from '@revelio/core';
import {
  SelectionCategories,
  ViewTypes,
  FilterSets,
  PrimaryFilterLimits,
  FilterMenuLimits,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  INDUSTRY_AND_COMPANY_FILTERS,
} from '@revelio/filtering';
import Compensation from '../../../compensation/compensation';

/* eslint-disable-next-line */
export interface CompanyPayProps {}

export function CompanyPay(props: CompanyPayProps) {
  const title = [PageTitles.COMPANY, PageTitles.PAY];

  const primaryViewFilters = [
    {
      filters: INDUSTRY_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 2,
    },
  ];
  const selectableFilters = [
    {
      filters: ROLE_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    SelectionCategories.DATE_RANGE_FULL,
  ];

  return (
    <Compensation
      title={title}
      viewType={ViewTypes.COMPANY}
      primaryFilter={PrimaryFilters.COMPANY}
      sharedFilterSetId={FilterSets.COMPANY}
      filterSet={FilterSets.COMPANY_COMPENSATION}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.COMPENSATION}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.COMPENSATION}
      disableParentOnPrimaryFilter={false}
      otherFilters={[]}
    />
  );
}

export default CompanyPay;
