import {
  Posting2dResponse,
  PostingMetric,
  graphql,
} from '@revelio/data-access';
import { GqlPlotName } from './gql.models';
import {
  GroupedBarData,
  GroupedBarValue,
  LineData,
  LineValue,
  MainPostingData,
  MainPostingValue,
} from '@revelio/d3';
import { SerializedFiltersForQuery } from '../filters.model';

const dateRegEx = /(.*)-(.*)-(.*)/;

interface GetPostingsOvertimeDataProps {
  entities: Posting2dResponse[];
}
const getMainPostingsData = ({
  entities,
}: GetPostingsOvertimeDataProps): MainPostingData[] => {
  return entities.map((entity): MainPostingData => {
    const { metadata: entityMetadata } = entity;

    return {
      id: entityMetadata?.id,
      metadata: {
        shortName: entityMetadata?.shortName,
        longName: entityMetadata?.shortName,
      },
      value: (entity?.category || []).map((category): MainPostingValue => {
        const metadata = category?.metadata;
        const metrics = category?.metrics;

        const id = metadata?.id;
        const shortName = metadata?.shortName;

        const active = metrics?.active;
        const value_new = metrics?.new;
        const removed = metrics?.removed;
        const expected_hires = metrics?.expected_hires;

        const match = shortName?.match(dateRegEx) || ['0', '0', '0', '0'];
        const [, year, month, day] = match;

        return {
          id,
          metadata: {
            shortName,
            longName: shortName,
            day: Number(day),
            month: Number(month),
            year: Number(year),
          },
          value: [
            {
              id: 1,
              metadata: {
                shortName: 'Active',
                longName: 'Active Postings',
              },
              value: active,
            },
            {
              id: 2,
              metadata: { shortName: 'New', longName: 'New Postings' },
              value: value_new,
            },
            {
              id: 3,
              metadata: {
                shortName: 'Removed',
                longName: 'Removed Postings',
              },
              value: removed,
            },
            {
              id: 4,
              metadata: {
                shortName: 'Expected Hires',
                longName: 'Expected Hires',
              },
              value: expected_hires,
            },
          ],
        };
      }),
    };
  });
};

type PostingMetricKey = keyof Pick<
  PostingMetric,
  'active' | 'new' | 'removed' | 'expected_hires'
>;
const validPostingMetricKeys: PostingMetricKey[] = [
  'active',
  'new',
  'removed',
  'expected_hires',
];
interface GetLineData {
  entities: Posting2dResponse[];
  metricKey:
    | keyof Pick<PostingMetric, 'salary' | 'time_to_fill'>
    | PostingMetricKey;
}
export const getLineData = ({
  entities,
  metricKey,
}: GetLineData): LineData[] => {
  return entities.map((entity): LineData => {
    const { metadata: entityMetadata } = entity;

    const id = entityMetadata?.id;
    const shortName = entityMetadata?.shortName;

    return {
      id,
      metadata: { shortName, longName: shortName },
      value: (entity?.category || []).map((category): LineValue => {
        const metadata = category?.metadata;
        const metrics = category?.metrics;

        const id = metadata?.id;
        const shortName = metadata?.shortName;

        const value = metrics?.[metricKey];

        const match = shortName?.match(dateRegEx) || ['0', '0', '0', '0'];
        const [, year, month, day] = match;
        return {
          id,
          metadata: {
            shortName,
            longName: shortName,
            day: Number(day),
            month: Number(month),
            year: Number(year),
          },
          value,
        };
      }),
    };
  });
};

interface GetGroupedBarDataProps {
  entities: Posting2dResponse[];
}
export const getGroupedBarData = ({
  entities,
}: GetGroupedBarDataProps): GroupedBarData[] => {
  return entities.map((entity): GroupedBarData => {
    const { metadata: entityMetadata } = entity;

    return {
      id: entityMetadata?.id,
      metadata: {
        shortName: entityMetadata?.shortName,
        longName: entityMetadata?.shortName,
      },
      value: (entity?.category || []).map((category): GroupedBarValue => {
        const metadata = category?.metadata;
        const metrics = category?.metrics;

        const id = metadata?.id;
        const shortName = metadata?.shortName;

        const count = metrics?.count;
        const share = metrics?.share;

        return {
          id,
          metadata: { shortName, longName: shortName, count },
          value: share,
        };
      }),
    };
  });
};

type PostingPlotName =
  | GqlPlotName.POSTINGS_OVERTIME
  | GqlPlotName.POSTINGS_MULTI_OVERTIME
  | GqlPlotName.SALARY_OVERTIME
  | GqlPlotName.TIME_TO_FILL_OVERTIME
  | GqlPlotName.TOP_ROLES;

interface ConvertPostingResponseToPlotDataProps {
  entities: Posting2dResponse[];
  plotName: PostingPlotName;
  filters: SerializedFiltersForQuery;
}
type PostingPlotData = MainPostingData[] | GroupedBarData[] | LineData[];
export const convertPostingResponseToPlotData = ({
  entities,
  plotName,
  filters,
}: ConvertPostingResponseToPlotDataProps): PostingPlotData => {
  switch (plotName) {
    case GqlPlotName.POSTINGS_OVERTIME:
      return getMainPostingsData({ entities });

    case GqlPlotName.POSTINGS_MULTI_OVERTIME: {
      const postingMetric = filters.posting_metric;
      const postingMetricValidated = ((): PostingMetricKey => {
        if (
          validPostingMetricKeys.includes(postingMetric as PostingMetricKey)
        ) {
          return postingMetric as PostingMetricKey;
        } else throw new Error('Invalid posting metric');
      })();

      return getLineData({ entities, metricKey: postingMetricValidated });
    }
    case GqlPlotName.SALARY_OVERTIME:
      return getLineData({ entities, metricKey: 'salary' });
    case GqlPlotName.TIME_TO_FILL_OVERTIME:
      return getLineData({ entities, metricKey: 'time_to_fill' });

    case GqlPlotName.TOP_ROLES:
      return getGroupedBarData({ entities });
  }
};

export const POSTING_GET_DATA = graphql(`
  query PostingsData($filters: PostingFilters, $dim1: Dimension1) {
    posting(filters: $filters, dim1: $dim1, dim2: date) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          id
          shortName
          longName
        }
        metrics {
          salary
          time_to_fill

          active
          new
          removed
          expected_hires
        }
      }
    }
  }
`);

export const POSTING_TOP_ROLES_GET_DATA = graphql(`
  query PostingsRolesData($filters: PostingFilters, $dim1: Dimension1) {
    posting(filters: $filters, dim1: $dim1, dim2: top) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          id
          shortName
          longName
        }
        metrics {
          count
          share
        }
      }
    }
  }
`);
