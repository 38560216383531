import { RoleActivityAnalysis } from '../role-activity-analysis/role-activity-analysis';
import { GeographyActivityAnalysis } from '../geography-activity-analysis/geography-activity-analysis';
// import { Taxonomy } from '../taxonomy/taxonomy';
import DataDictionary from '../data-dictionary/data-dictionary';
import AdminRewrite from '../adminRewrite/admin';
import { NavItemConfig, RouteNavConfig } from '@revelio/auth';
import { FilterSets, ViewTypes } from '@revelio/filtering';
import { PagePaths, PageTitles, PrimaryFilters, Views } from '@revelio/core';
import { Tab } from '@revelio/data-access';
import CompanyCompositions from '../view-groups/company/company-compositions/company-compositions';
import GeographyCompositions from '../view-groups/geography/geography-compositions/geography-compositions';
import RoleCompositions from '../view-groups/role/role-compositions/role-compositions';
import CompanyPostings from '../view-groups/company/company-postings/company-postings';
import RolePostings from '../view-groups/role/role-postings/role-postings';
import GeographyPostings from '../view-groups/geography/geography-postings/geography-postings';
import CompanyTransitions from '../view-groups/company/company-transitions/company-transitions';
import GeographyTransitions from '../view-groups/geography/geography-transitions/geography-transitions';
import RoleTransitions from '../view-groups/role/role-transitions/role-transitions';
import CompanyScreener from '../view-groups/company/company-screener/company-screener';
import GeographyScreener from '../view-groups/geography/geography-screener/geography-screener';
import RoleScreener from '../view-groups/role/role-screener/role-screener';
import { environment } from '../../environments/environment';
import AdminRewriteUserEdit from '../adminRewrite/admin-user-edit/admin-user-edit';
import AdminUserAdd from '../adminRewrite/admin-user-add/admin-user-add';
import UserEdit from '../adminRewrite/user-edit/user-edit';
import ClientAdmin from '../adminRewrite/client-admin';
import { ContactSalesButton } from '@revelio/composed';
import { DeliverablePaths } from '../deliverables/deliverables-page';
import { DataSet } from '../deliverables/data-set';
import { Delivery } from '../deliverables/delivery/delivery';
import { Confirmation } from '../deliverables/confirmation/confirmation';
import { DeliverablesStatus } from '../deliverables/status/deliverables-status';
import RolePay from '../view-groups/role/role-pay/role-pay';
import GeographyPay from '../view-groups/geography/geography-pay/geography-pay';
import CompanyPay from '../view-groups/company/company-pay/company-pay';
import { Outlet } from 'react-router-dom';
import CompanySentiment from '../view-groups/company/company-sentiment/company-sentiment';
import RoleSentiment from '../view-groups/role/role-sentiment/role-sentiment';
import GeographySentiment from '../view-groups/geography/geography-sentiment/geography-sentiment';
import CompanySelectionStep from '../deliverables/company-selection/company-selection-step';
import { DatasetConfiguration } from '../deliverables/columns/data-set-configuration';
import AdminClientEdit from '../adminRewrite/admin-client-edit/admin-client-edit';
import SentimentRatings from '../sentiment-ratings/sentiment-ratings';

import ResumeEnrichment from '../resume-enrichment/ResumeEnrichment';
import EnrichedResumePage from '../resume-enrichment/EnrichedResumePage';
import { EntityConfiguration } from '../deliverables/report-builder/entity-configuration/entity-configuration';
import { ReportBuilderDeliverablePaths } from '../deliverables/report-builder/report-builder-page';
import { ReportConfirmation } from '../deliverables/report-builder/report-confirmation/report-confirmation';
import { ReportType } from '../deliverables/report-builder/report-type/report-type';
import { ReportBuilder } from '../deliverables/report-builder/report-builder';
import { Deliverables } from '../deliverables/deliverables';
import { DeliverablesDataEnrichment } from '../deliverables/data-enrichment/DeliverablesDataEnrichment';
import ReportsPage from '../reports/reports-page';
import ReportPage from '../reports/report-page';
import { TalentDiscovery } from '../talent-discovery/talent-discovery';
import { iconUrls } from './side-nav/icons';
import SampleReportPage from '../reports/sample-report.page';
import { CompanySummary } from '../entity-summary/company-summary/company-summary';
import { GeographySummary } from '../entity-summary/geography-summary/geography-summary';
import { RoleSummary } from '../entity-summary/role-summary/role-summary';
// import { GeographySummary } from '../entity-summary/geography-summary/geography-summary';
// import { RoleSummary } from '../entity-summary/role-summary/role-summary';

export const navRouteItems: (RouteNavConfig | NavItemConfig)[] = [
  {
    name: PageTitles.COMPANY,
    icon: iconUrls.company,
    isSourcesTab: true,
    children: [
      {
        name: PageTitles.SUMMARY,
        serverTab: Tab.EntitySummary,
        path: PagePaths.COMPANY_SUMMARY,
        element: <CompanySummary />,
        parent: PageTitles.COMPANY,
      },
      {
        name: PageTitles.COMPOSITIONS,
        serverTab: Tab.Overview,
        path: PagePaths.COMPANY_COMPOSITIONS,
        element: <CompanyCompositions />,
        parent: PageTitles.COMPANY,
      },
      {
        name: PageTitles.TRANSITIONS,
        serverTab: Tab.Transition,
        path: PagePaths.COMPANY_TRANSITIONS,
        element: <CompanyTransitions />,
        parent: PageTitles.COMPANY,
      },
      {
        name: PageTitles.JOB_POSTINGS,
        serverTab: Tab.Posting,
        path: PagePaths.COMPANY_POSTINGS,
        element: <CompanyPostings />,
        parent: PageTitles.COMPANY,
      },
      {
        name: PageTitles.PAY,
        serverTab: Tab.Compensation,
        path: PagePaths.COMPANY_PAY,
        element: <CompanyPay />,
        parent: PageTitles.COMPANY,
      },
      {
        name: PageTitles.SENTIMENT,
        children: [
          {
            name: PageTitles.SENTIMENT_RATINGS,
            serverTab: Tab.SentimentV1,
            path: PagePaths.COMPANY_SENTIMENT_RATINGS,
            element: (
              <SentimentRatings
                key={PagePaths.COMPANY_SENTIMENT_RATINGS}
                pageTitle={PageTitles.COMPANY}
                primaryFilter={PrimaryFilters.COMPANY}
                view={Views.SENTIMENT}
                viewType={ViewTypes.COMPANY}
                sharedFilterSetId={FilterSets.COMPANY}
                filterSet={FilterSets.COMPANY_RATINGS}
                trialNoResultsMessage={
                  <>
                    Can't find a company?
                    <ContactSalesButton
                      buttonProps={{
                        size: 'sm',
                        variant: 'link',
                        fontWeight: 400,
                        paddingTop: 2,
                        paddingLeft: 1,
                      }}
                      page={PageTitles.SENTIMENT_RATINGS}
                    />
                  </>
                }
              />
            ),
            parent: PageTitles.COMPANY,
          },
          {
            name: PageTitles.SENTIMENT_REVIEWS_EFFECTS,
            serverTab: Tab.SentimentV1,
            path: PagePaths.COMPANY_SENTIMENT_REVIEWS,
            element: <CompanySentiment />,
            parent: PageTitles.COMPANY,
          },
        ],
      },
      // {
      //   name: PageTitles.LAYOFF,
      //   serverTab: Tab.Layoff,
      //   path: 'company/layoffs',
      //   element: <Layoff />,
      //   parent: PageTitles.COMPANY,
      // },
      {
        name: PageTitles.SENTIMENT,
        serverTab: Tab.Sentiment,
        path: PagePaths.COMPANY_SENTIMENT,
        element: <CompanySentiment />,
        parent: PageTitles.COMPANY,
      },
      {
        name: PageTitles.SCREENER,
        serverTab: Tab.Screener,
        path: 'company/screener',
        element: <CompanyScreener />,
        parent: PageTitles.COMPANY,
      },
    ],
  },

  {
    name: PageTitles.GEOGRAPHY,
    icon: iconUrls.geography,
    isSourcesTab: true,
    children: [
      {
        name: PageTitles.SUMMARY,
        serverTab: Tab.EntitySummary,
        path: PagePaths.GEOGRAPHY_SUMMARY,
        element: <GeographySummary />,
        parent: PageTitles.COMPANY,
      },
      {
        name: PageTitles.COMPOSITIONS,
        serverTab: Tab.Overview,
        path: PagePaths.GEOGRAPHY_COMPOSITIONS,
        element: <GeographyCompositions />,
        parent: PageTitles.GEOGRAPHY,
      },
      {
        name: PageTitles.TRANSITIONS,
        serverTab: Tab.Transition,
        path: PagePaths.GEOGRAPHY_TRANSITIONS,
        element: <GeographyTransitions />,
        parent: PageTitles.GEOGRAPHY,
      },
      {
        name: PageTitles.JOB_POSTINGS,
        serverTab: Tab.Posting,
        path: PagePaths.GEOGRAPHY_POSTINGS,
        element: <GeographyPostings />,
        parent: PageTitles.GEOGRAPHY,
      },
      {
        name: PageTitles.PAY,
        serverTab: Tab.Compensation,
        path: PagePaths.GEOGRAPHY_PAY,
        element: <GeographyPay />,
        parent: PageTitles.GEOGRAPHY,
      },
      {
        name: PageTitles.SENTIMENT,
        children: [
          {
            name: PageTitles.SENTIMENT_RATINGS,
            serverTab: Tab.SentimentV1,
            path: PagePaths.GEOGRAPHY_SENTIMENT_RATINGS,
            element: (
              <SentimentRatings
                key={PagePaths.GEOGRAPHY_SENTIMENT_RATINGS}
                pageTitle={PageTitles.GEOGRAPHY}
                primaryFilter={PrimaryFilters.METRO_AREA}
                view={Views.SENTIMENT}
                viewType={ViewTypes.GEO}
                sharedFilterSetId={FilterSets.GEOGRAPHY}
                filterSet={FilterSets.GEOGRAPHY_SENTIMENT_RATINGS}
              />
            ),
            parent: PageTitles.GEOGRAPHY,
          },
          {
            name: PageTitles.SENTIMENT_REVIEWS_EFFECTS,
            serverTab: Tab.SentimentV1,
            path: PagePaths.GEOGRAPHY_SENTIMENT_REVIEWS,
            element: <GeographySentiment />,
            parent: PageTitles.GEOGRAPHY,
          },
        ],
      },
      // {
      //   name: PageTitles.LAYOFF,
      //   serverTab: Tab.Layoff,
      //   path: 'geography/layoffs',
      //   element: <Layoff />,
      //   parent: PageTitles.GEOGRAPHY,
      // },
      {
        name: PageTitles.SENTIMENT,
        serverTab: Tab.Sentiment,
        path: PagePaths.GEOGRAPHY_SENTIMENT,
        element: <GeographySentiment />,
        parent: PageTitles.GEOGRAPHY,
      },
      {
        name: PageTitles.SCREENER,
        serverTab: Tab.Screener,
        path: 'geography/screener',
        element: <GeographyScreener />,
        parent: PageTitles.GEOGRAPHY,
      },
      !environment.production
        ? {
            name: PageTitles.ACTIVITY_ANALYSIS,
            serverTab: Tab.JobAnalysis,
            path: 'geography/activity-analysis',
            element: <GeographyActivityAnalysis />,
            parent: PageTitles.GEOGRAPHY,
          }
        : {},
    ],
  },

  {
    name: PageTitles.ROLE,
    icon: iconUrls.role,
    isSourcesTab: true,
    children: [
      {
        name: PageTitles.SUMMARY,
        serverTab: Tab.EntitySummary,
        path: PagePaths.ROLE_SUMMARY,
        element: <RoleSummary />,
        parent: PageTitles.COMPANY,
      },
      {
        name: PageTitles.COMPOSITIONS,
        serverTab: Tab.Overview,
        path: PagePaths.ROLE_COMPOSITIONS,
        element: <RoleCompositions />,
        parent: PageTitles.ROLE,
      },
      {
        name: PageTitles.TRANSITIONS,
        serverTab: Tab.Transition,
        path: PagePaths.ROLE_TRANSITIONS,
        element: <RoleTransitions />,
        parent: PageTitles.ROLE,
      },
      {
        name: PageTitles.JOB_POSTINGS,
        serverTab: Tab.Posting,
        path: PagePaths.ROLE_POSTINGS,
        element: <RolePostings />,
        parent: PageTitles.ROLE,
      },
      {
        name: PageTitles.PAY,
        serverTab: Tab.Compensation,
        path: PagePaths.ROLE_PAY,
        element: <RolePay />,
        parent: PageTitles.ROLE,
      },
      {
        name: PageTitles.SENTIMENT,
        children: [
          {
            name: PageTitles.SENTIMENT_RATINGS,
            serverTab: Tab.SentimentV1,
            path: PagePaths.ROLE_SENTIMENT_RATINGS,
            element: (
              <SentimentRatings
                key={PagePaths.ROLE_SENTIMENT_RATINGS}
                pageTitle={PageTitles.ROLE}
                primaryFilter={PrimaryFilters.ROLE}
                view={Views.SENTIMENT}
                viewType={ViewTypes.ROLE}
                sharedFilterSetId={FilterSets.ROLE}
                filterSet={FilterSets.ROLE_SENTIMENT_RATINGS}
              />
            ),
            parent: PageTitles.ROLE,
          },
          {
            name: PageTitles.SENTIMENT_REVIEWS_EFFECTS,
            serverTab: Tab.SentimentV1,
            path: PagePaths.ROLE_SENTIMENT_REVIEWS,
            element: <RoleSentiment />,
            parent: PageTitles.ROLE,
          },
        ],
      },
      // {
      //   name: PageTitles.LAYOFF,
      //   serverTab: Tab.Layoff,
      //   path: 'role/layoffs',
      //   element: <Layoff />,
      //   parent: PageTitles.ROLE,
      // },
      {
        name: PageTitles.SENTIMENT,
        serverTab: Tab.Sentiment,
        path: PagePaths.ROLE_SENTIMENT,
        element: <RoleSentiment />,
        parent: PageTitles.ROLE,
      },
      {
        name: PageTitles.SCREENER,
        serverTab: Tab.Screener,
        path: 'role/screener',
        element: <RoleScreener />,
        parent: PageTitles.ROLE,
      },
      !environment.production
        ? {
            name: PageTitles.ACTIVITY_ANALYSIS,
            serverTab: Tab.JobAnalysis,
            path: 'role/activity-analysis',
            element: <RoleActivityAnalysis />,
            parent: PageTitles.ROLE,
          }
        : {},
    ],
  },

  {
    name: PageTitles.INDIVIDUAL,
    icon: iconUrls.individuals,
    isSourcesTab: true,
    children: [
      {
        name: PageTitles.TALENT_DISCOVERY,
        serverTab: Tab.TalentDiscovery,
        path: 'talent-discovery',
        element: <TalentDiscovery />,
      },
      {
        name: PageTitles.RESUME_ENRICHMENT,
        serverTab: Tab.ResumeParsing,
        path: 'resume-enrichment',
        element: <Outlet />,
        nestedRoutes: [
          {
            path: ':id',
            element: <EnrichedResumePage />,
            serverTab: Tab.ResumeParsing,
            parent: PageTitles.INDIVIDUAL,
          },
          {
            path: '',
            element: <ResumeEnrichment />,
            serverTab: Tab.ResumeParsing,
            parent: PageTitles.INDIVIDUAL,
          },
        ],
      },
    ],
  },
  {
    name: PageTitles.DELIVERABLES,
    icon: iconUrls.deliverables,
    isSourcesTab: true,
    divider: true,
    children: [
      {
        name: PageTitles.DATA_BUILDER,
        path: PagePaths.DELIVERABLES,
        serverTab: Tab.Deliverables,
        element: <Deliverables />,
        nestedRoutes: [
          {
            path: DeliverablePaths.DATASET,
            element: <DataSet />,
          },
          {
            path: DeliverablePaths.COMPANY_SELECTION,
            element: <CompanySelectionStep />,
          },
          {
            path: DeliverablePaths.COLUMNS,
            element: <DatasetConfiguration />,
          },
          {
            path: DeliverablePaths.DELIVERY,
            element: <Delivery />,
          },
          {
            path: DeliverablePaths.CONFIRMATION,
            element: <Confirmation />,
          },
        ],
      },
      {
        name: PageTitles.DELIVERABLES_STATUS,
        serverTab: Tab.Deliverables,
        path: `${PagePaths.DELIVERABLES}/${DeliverablePaths.STATUS}`,
        element: <DeliverablesStatus />,
      },
      {
        name: PageTitles.DATA_ENRICHMENT,
        serverTab: Tab.DataEnrichment,
        path: `${PagePaths.DELIVERABLES}/${PagePaths.DATA_ENRICHMENT}`,
        element: <DeliverablesDataEnrichment />,
      },
      {
        name: PageTitles.REPORT_BUILDER,
        path: PagePaths.REPORT_BUILDER,
        serverTab: Tab.Reports,
        subHeading: 'Reports',
        element: <ReportBuilder />,
        nestedRoutes: [
          {
            path: ReportBuilderDeliverablePaths.REPORT_TYPE,
            element: <ReportType />,
          },
          {
            path: ReportBuilderDeliverablePaths.ENTITY,
            element: <EntityConfiguration />,
          },
          {
            path: ReportBuilderDeliverablePaths.CONFIRMATION,
            element: <ReportConfirmation />,
          },
        ],
      },
      {
        name: PageTitles.REPORTS,
        path: PagePaths.REPORTS,
        serverTab: Tab.Reports,
        element: <Outlet />,
        nestedRoutes: [
          {
            path: '',
            element: <ReportsPage />,
            serverTab: [Tab.Deliverables, Tab.Reports],
            parent: PageTitles.REPORTS,
          },
          {
            path: ':id',
            element: <ReportPage />,
            serverTab: [Tab.Deliverables, Tab.Reports],
            parent: PageTitles.REPORTS,
          },
        ],
      },
      {
        name: PageTitles.REPORTS_SAMPLE,
        path: PagePaths.REPORTS_SAMPLE,
        element: <SampleReportPage />,
      },
    ],
  },
  {
    name: PageTitles.DOCUMENTATION,
    icon: iconUrls.documentation,
    divider: true,
    isSourcesTab: false,
    children: [
      {
        name: 'Data Dictionary',
        serverTab: Tab.DataDictionary,
        path: 'data-dictionary',
        element: <DataDictionary />,
        parent: PageTitles.DOCUMENTATION,
      },
      // {
      //   name: PageTitles.TAXONOMY,
      //   serverTab: Tab.TaxonomyVisualizer,
      //   path: 'taxonomy',
      //   element: <Taxonomy />,
      //   parent: PageTitles.DOCUMENTATION,
      // },
    ],
  },
  // disabled until polished a bit more for release (after newauth release)
  // {
  //   name: PageTitles.FEEDBACK,
  //   serverTab: Tab.Overview,
  //   parent: PageTitles.DOCUMENTATION,
  //   icon: iconUrls.help,
  //   elementId: 'feedback-button',
  //   isSourcesTab: false,
  // },
  /* BEGIN ADMIN REWRITE -- Jeremy Odell */
  {
    name: 'Admin',
    path: 'admin/:clientId?',
    admin: true,
    element: <AdminRewrite />,
  },
  {
    name: 'Admin User Edit',
    path: 'admin/user/:userId/edit',
    admin: true,
    clientAdmin: true,
    element: <AdminRewriteUserEdit />,
  },
  {
    name: 'Admin Client Edit',
    path: 'admin/client/:clientId/edit',
    admin: true,
    clientAdmin: true,
    element: <AdminClientEdit />,
  },
  {
    name: 'Admin User Add',
    path: 'admin/user/add/:clientId?',
    admin: true,
    clientAdmin: true,
    element: <AdminUserAdd />,
  },
  /* END ADMIN REWRITE -- Jeremy Odell */
  {
    name: 'Manage',
    path: 'manage',
    clientAdmin: true,
    element: <ClientAdmin />,
  },
  {
    name: 'Manage User Edit',
    path: 'manage/user/:userId/edit',
    element: <UserEdit />,
  },
];
