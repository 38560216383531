import { SkillRefInfo } from '@revelio/data-access';
import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { BehaviorSubject, filter, startWith } from 'rxjs';

const skillFiltersSubject$ = new BehaviorSubject<SkillFilterState>([]);
export const skillFilters$ = skillFiltersSubject$.pipe(
  filter((skillFilters) => skillFilters.length > 0),
  startWith([])
);

type SkillFilterValue = {
  id: SkillLevel;
  value: SkillRefInfo[];
};

export type SkillLevel = 'skill_k75' | 'skill_k700' | 'skill_k3000';
type SkillFilter = {
  skillLevel: SkillLevel;
  id: number;
};

/** Filters with Chip are OR'd together */
type SkillChip = SkillFilter[];

/** Skill Chips are AND'd together */
export type SkillFilterState = SkillChip[];

const createSkillFilter = ({
  skillRef,
  skillLevel,
}: {
  skillRef: SkillRefInfo;
  skillLevel: SkillLevel;
}): SkillFilter | null => {
  const id = Number(skillRef.id);
  if (!isNumber(id)) return null;

  return { skillLevel, id };
};

const createSkillChip = ({
  selections,
}: {
  selections: SkillSelections;
}): SkillChip => {
  return [
    ...(selections?.skill_k75 ?? []).map((skillRef) =>
      createSkillFilter({ skillRef, skillLevel: 'skill_k75' })
    ),
    ...(selections?.skill_k700 ?? []).map((skillRef) =>
      createSkillFilter({ skillRef, skillLevel: 'skill_k700' })
    ),
    ...(selections?.skill_k3000 ?? []).map((skillRef) =>
      createSkillFilter({ skillRef, skillLevel: 'skill_k3000' })
    ),
  ].filter((skillFilter): skillFilter is SkillFilter => skillFilter !== null);
};

type SkillSelections = {
  skill_k75: SkillRefInfo[];
  skill_k700: SkillRefInfo[];
  skill_k3000: SkillRefInfo[];
};

const generateAllSelectedSkills = ({
  skillFilters,
  activeFilters,
  selections,
}: {
  skillFilters: SkillFilterState;
  activeFilters: SkillFilterValue[] | undefined;
  selections: SkillSelections;
}): SkillSelections => {
  // List of all possible values for each level
  const k75FilterChipValues = new Set(
    skillFilters.flatMap((filterChip) =>
      filterChip
        .filter((filter) => filter.skillLevel === 'skill_k75')
        .map((filter) => filter.id)
    )
  );
  const k700FilterChipValues = new Set(
    skillFilters.flatMap((filterChip) =>
      filterChip
        .filter((filter) => filter.skillLevel === 'skill_k700')
        .map((filter) => filter.id)
    )
  );
  const k3000FilterChipValues = new Set(
    skillFilters.flatMap((filterChip) =>
      filterChip
        .filter((filter) => filter.skillLevel === 'skill_k3000')
        .map((filter) => filter.id)
    )
  );

  // all active values from prior selections
  const activeK75FilterValues =
    activeFilters?.find((filter) => filter.id === 'skill_k75')?.value ?? [];
  const activeK700FilterValues =
    activeFilters?.find((filter) => filter.id === 'skill_k700')?.value ?? [];
  const activeK3000FilterValues =
    activeFilters?.find((filter) => filter.id === 'skill_k3000')?.value ?? [];

  // join active values with new chip values
  const k75Selections = [
    ...(selections?.skill_k75 ?? []),
    ...activeK75FilterValues,
  ].filter((skill) => k75FilterChipValues.has(Number(skill.id)));
  const k700Selections = [
    ...(selections?.skill_k700 ?? []),
    ...activeK700FilterValues,
  ].filter((skill) => k700FilterChipValues.has(Number(skill.id)));
  const k3000Selections = [
    ...(selections?.skill_k3000 ?? []),
    ...activeK3000FilterValues,
  ].filter((skill) => k3000FilterChipValues.has(Number(skill.id)));

  return {
    ...selections,
    skill_k75: k75Selections,
    skill_k700: k700Selections,
    skill_k3000: k3000Selections,
  };
};

export const useSkillFiltersApi = () => {
  const [skillFilters, setSkillFilters] = useState<SkillFilterState>([]);

  useEffect(() => {
    skillFiltersSubject$.next(skillFilters);
  }, [skillFilters]);

  const addFilterChip = ({
    selections,
    activeFilters,
  }: {
    selections: SkillSelections;
    activeFilters: SkillFilterValue[] | undefined;
  }): SkillSelections => {
    // Define new chip
    const newSkillChip = createSkillChip({ selections });

    // create new filters
    const newSkillFilters = [...skillFilters, newSkillChip];
    setSkillFilters(newSkillFilters);

    return generateAllSelectedSkills({
      skillFilters: newSkillFilters,
      activeFilters,
      selections,
    });
  };

  const breakoutFilterChips = (
    items: { id: SkillLevel; value: Partial<SkillSelections> }[]
  ): { id: SkillLevel; value: Partial<SkillSelections> }[] => {
    return items.flatMap((item) => {
      if (['skill_k75', 'skill_k700', 'skill_k3000'].includes(item.id)) {
        return skillFilters.map((filterChip, i) => {
          const skill_k75 = filterChip
            .filter((filter) => filter.skillLevel === 'skill_k75')
            .map((filter) =>
              item?.value?.skill_k75?.find(
                (skill) => Number(skill?.id) === filter?.id
              )
            )
            .filter((filter): filter is SkillRefInfo => !!filter);
          const skill_k700 = filterChip
            .filter((filter) => filter.skillLevel === 'skill_k700')
            .map((filter) =>
              item?.value?.skill_k700?.find(
                (skill) => Number(skill?.id) === filter?.id
              )
            )
            .filter((filter): filter is SkillRefInfo => !!filter);
          const skill_k3000 = filterChip
            .filter((filter) => filter.skillLevel === 'skill_k3000')
            .map((filter) =>
              item?.value?.skill_k3000?.find(
                (skill) => Number(skill?.id) === filter?.id
              )
            )
            .filter((filter): filter is SkillRefInfo => !!filter);

          return {
            ...item,
            filterStateIndex: i,
            value: { skill_k75, skill_k700, skill_k3000 },
          };
        });
      }
      return item;
    });
  };

  const updateFilterChip = ({
    index,
    selections,
    activeFilters,
  }: {
    index: number;
    selections: SkillSelections;
    activeFilters: SkillFilterValue[] | undefined;
  }): SkillSelections => {
    const updatedSkillChip = createSkillChip({ selections });

    const newSkillFilters = [
      ...skillFilters.slice(0, index),
      updatedSkillChip,
      ...skillFilters.slice(index + 1),
    ];
    setSkillFilters(newSkillFilters);

    const activeK75FilterValues = (
      activeFilters?.find((filter) => filter.id === 'skill_k75')?.value ?? []
    ).filter(
      (activeFilter) =>
        !selections.skill_k75
          .map((selection) => selection.id)
          .includes(activeFilter.id)
    );
    const activeK700FilterValues = (
      activeFilters?.find((filter) => filter.id === 'skill_k700')?.value ?? []
    ).filter(
      (activeFilter) =>
        !selections.skill_k700
          .map((selection) => selection.id)
          .includes(activeFilter.id)
    );
    const activeK3000FilterValues = (
      activeFilters?.find((filter) => filter.id === 'skill_k3000')?.value ?? []
    ).filter(
      (activeFilter) =>
        !selections.skill_k3000
          .map((selection) => selection.id)
          .includes(activeFilter.id)
    );

    return generateAllSelectedSkills({
      skillFilters: newSkillFilters,
      activeFilters: [
        { id: 'skill_k75', value: activeK75FilterValues },
        { id: 'skill_k700', value: activeK700FilterValues },
        { id: 'skill_k3000', value: activeK3000FilterValues },
      ],
      selections,
    });
  };

  const removeFilterChip = (filterIndex: number, cb: () => void) => {
    setSkillFilters((prevState) => {
      const newState = prevState.filter((_, i) => i !== filterIndex);

      if (newState.length === 0) cb();

      return newState;
    });
  };

  return {
    skillFilters,
    addFilterChip,
    breakoutFilterChips,
    updateFilterChip,
    removeFilterChip,
  };
};

export type SkillFilterApi = ReturnType<typeof useSkillFiltersApi>;
