import { Box, Button, Icon, Link } from '@chakra-ui/react';
import { setGlobalLoaderEnableState, WithTooltip } from '@revelio/core';
import { DownloadBuildRequest, useActiveFiltersV2 } from '@revelio/filtering';
import { plotDataDownloader } from '@revelio/filtering';
import { useState } from 'react';
import { FiDownload } from 'react-icons/fi';

// export type DownloadButtonProps = DownloadBuildRequest;

export interface DownloadButtonProps extends DownloadBuildRequest {
  text?: string;
  justify?: string;
  borderRadius?: string;
  isText?: boolean;
  disabled?: boolean;
  useLoading?: boolean;
  loadingText?: string;
}

export const DownloadButton = ({
  endpoint,
  brokenOutFilterIds,
  additionalNonActiveFilters,
  additionalOperatorsBeforeQuery,
  requestMethod,
  justify = 'center',
  borderRadius,
  isText = false,
  text = 'Download Data',
  disabled,
  useLoading = false,
  loadingText = '',
  isGoRequest = false,
  view,
}: DownloadButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const triggerDownload = () => {
    if (useLoading) {
      setIsLoading(true);
    }
    if (!disabled) {
      setGlobalLoaderEnableState('DISABLE');
      plotDataDownloader({
        endpoint,
        requestMethod,
        brokenOutFilterIds,
        additionalNonActiveFilters,
        additionalOperatorsBeforeQuery,
        callback: () => {
          setGlobalLoaderEnableState('ENABLE');
          setIsLoading(false);
        },
        isGoRequest,
        view,
      });
    }
  };
  const [activeFilters] = useActiveFiltersV2();
  return (
    <WithTooltip
      showCondition={!!disabled}
      placement="top"
      variant="label"
      padding="10px 15px"
      label="This feature is not available for trial users"
      aria-label={`This feature is not available for trial users`}
    >
      <Box>
        {/* eslint-disable-next-line */}
        {activeFilters?.length > 0 ? (
          isText ? (
            <Link onClick={triggerDownload} isExternal variant="tooltip">
              click here
            </Link>
          ) : (
            <Button
              isLoading={isLoading}
              loadingText={loadingText}
              leftIcon={<Icon as={FiDownload} boxSize={3.5} />}
              size="sm"
              variant="white"
              onClick={triggerDownload}
              justifyContent={justify}
              borderRadius={borderRadius}
              data-testid="download-data-button"
              isDisabled={disabled}
            >
              {text}
            </Button>
          )
        ) : null}
      </Box>
    </WithTooltip>
  );
};
