import { Box, Flex, Text } from '@chakra-ui/layout';
import { Card } from '@chakra-ui/react';
import { BarChartHorizontal } from '@revelio/d3';
import { CompositionDataQuery } from '@revelio/data-access';
import { Select } from 'chakra-react-select';
import { useMemo, useState } from 'react';
import { getCompetitorBarChartProps } from './utils/get-competitor-bar-chart-props';
import { getCompetitorCompositionData } from './utils/get-competitor-composition-data';
import {
  CompanySummaryMetric,
  CompetitorBarChartsData,
  SingleOption,
} from '../../types';

interface CompetitorBarChartsProps {
  data: CompositionDataQuery | undefined;
  primaryRCID: number;
}

export const CompetitorBarCharts = ({
  data,
  primaryRCID,
}: CompetitorBarChartsProps) => {
  const compositionData: CompetitorBarChartsData = useMemo(
    () => getCompetitorCompositionData(data, primaryRCID),
    [data, primaryRCID]
  );

  const [selectedChart, setSelectedChart] = useState<SingleOption>({
    value: 'headcount',
    label: 'Headcount',
  });

  const chartProps = useMemo(
    () => getCompetitorBarChartProps(compositionData, selectedChart),
    [compositionData, selectedChart]
  );

  return (
    <Card height="100%">
      <Box height="100%" p={4}>
        <Flex justifyContent="space-between">
          <Text fontWeight={600}>Competitors</Text>
          <Select
            value={selectedChart}
            onChange={(value) => setSelectedChart(value)}
            options={[
              { value: CompanySummaryMetric.Headcount, label: 'Headcount' },
              {
                value: CompanySummaryMetric.AttritionRate,
                label: 'Attrition Rate',
              },
              { value: CompanySummaryMetric.GrowthRate, label: 'Growth Rate' },
              { value: CompanySummaryMetric.HiringRate, label: 'Hiring Rate' },
            ]}
            chakraStyles={{
              control: (prev) => ({
                ...prev,
                backgroundColor: '#F1F5F9',
              }),
            }}
          />
        </Flex>

        <Box height="80%">
          <BarChartHorizontal {...chartProps} />
        </Box>
      </Box>
    </Card>
  );
};
