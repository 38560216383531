import { isNil, isNumber, round } from 'lodash';
import {
  graphql,
  CompositionResponse,
  CompositionMetrics,
  SingleValueMetric,
  CategoryMetric,
  CategoryTimeseries,
  CompositionMetadata,
  TimeDataPoint,
  Maybe,
} from '@revelio/data-access';
import { GqlPlotName } from './gql.models';
import {
  LineData,
  BarChartHorizontalData,
  StackedBarChartHorizontalData,
  LineValue,
  StackedBarChartHorizontalValue,
} from '@revelio/d3';
import { SerializedFiltersForQuery } from '../filters.model';

/** ======== Convert Overtime Values ======== */
const compositionOvertimePlotNames = [
  GqlPlotName.HEADCOUNT_OVERTIME,
  GqlPlotName.GROWTH_RATE_OVERTIME,
  GqlPlotName.HIRING_RATE_OVERTIME,
  GqlPlotName.ATTRITION_RATE_OVERTIME,
  GqlPlotName.TENURE_OVERTIME,
  GqlPlotName.SALARY_OVERTIME,
  GqlPlotName.ROLE_OVERTIME,
  GqlPlotName.GEOGRAPHY_OVERTIME,
  GqlPlotName.SENIORITY_OVERTIME,
  GqlPlotName.SKILL_OVERTIME,
  GqlPlotName.GENDER_OVERTIME,
  GqlPlotName.ETHNICITY_OVERTIME,
  GqlPlotName.EDUCATION_OVERTIME,
  GqlPlotName.INDUSTRY_OVERTIME,
] as const;
type CompositionOvertimePlotName =
  (typeof compositionOvertimePlotNames)[number];
const compositionOvertimePlotNameMetricLookup: Record<
  CompositionOvertimePlotName,
  keyof Omit<CompositionMetrics, '__typename'>
> = {
  headcount_overtime: 'headcount',
  growth_rate_overtime: 'growth_rate',
  hiring_rate_overtime: 'hiring_rate',
  attrition_rate_overtime: 'attrition_rate',
  tenure_overtime: 'tenure',
  salary_overtime: 'salary',
  role_overtime: 'job_categories',
  geo_overtime: 'geographies',
  seniority_overtime: 'seniorities',
  skill_overtime: 'skills',
  gender_overtime: 'genders',
  ethnicity_overtime: 'ethnicities',
  education_overtime: 'educations',
  industry_overtime: 'industries',
};

const compositionSnapshotPlotNames = [
  GqlPlotName.HEADCOUNT,
  GqlPlotName.GROWTH_RATE,
  GqlPlotName.HIRING_RATE,
  GqlPlotName.ATTRITION_RATE,
  GqlPlotName.TENURE,
  GqlPlotName.SALARY,
  GqlPlotName.ROLE,
  GqlPlotName.GEOGRAPHY,
  GqlPlotName.SENIORITY,
  GqlPlotName.SKILL,
  GqlPlotName.GENDER,
  GqlPlotName.ETHNICITY,
  GqlPlotName.EDUCATION,
  GqlPlotName.INDUSTRY,
] as const;
type CompositionSnapshotPlotName =
  (typeof compositionSnapshotPlotNames)[number];

const compositionSnapshotPlotNameMetricLookup: Record<
  CompositionSnapshotPlotName,
  keyof Omit<CompositionMetrics, '__typename'>
> = {
  headcount: 'headcount',
  growth_rate: 'growth_rate',
  hiring_rate: 'hiring_rate',
  attrition_rate: 'attrition_rate',
  tenure: 'tenure',
  salary: 'salary',
  role: 'job_categories',
  geo: 'geographies',
  seniority: 'seniorities',
  skill: 'skills',
  gender: 'genders',
  ethnicity: 'ethnicities',
  education: 'educations',
  industry: 'industries',
};

const hasNoCategoryMetadata = (
  metadata: Maybe<CompositionMetadata> | undefined
): boolean =>
  isNil(metadata) || isNil(metadata.id) || isNil(metadata.shortName);
const isNotValidCategoryMetric = (
  categoryMetric: CategoryTimeseries
): boolean => {
  const hasNoTimeseriesData =
    isNil(categoryMetric.timeseries) || categoryMetric.timeseries.length === 0;
  return (
    isNil(categoryMetric) ||
    hasNoCategoryMetadata(categoryMetric.metadata) ||
    hasNoTimeseriesData
  );
};

interface GetCompositionOvertimeValuesProps {
  metrics: CompositionResponse['metrics'];
  plotName: CompositionOvertimePlotName;
  subfilters?: string[];
}
export const getCompositionOvertimeValues = ({
  metrics,
  plotName,
  subfilters = [],
}: GetCompositionOvertimeValuesProps): LineValue[] => {
  if (isNil(metrics)) return [];

  const metricName = compositionOvertimePlotNameMetricLookup[plotName];
  const metricValue = metrics[metricName];

  if (isNil(metricValue)) return [];

  if (isCategoryMetric(metricValue)) {
    const modelTimeSeries = metricValue.category?.find(
      (category) => category?.timeseries && category?.timeseries?.length > 0
    )?.timeseries as TimeDataPoint[];
    const timeSeriesLength = modelTimeSeries.length as number;
    const lineChartData: LineValue[] = [];
    for (let i = 0; i < timeSeriesLength; i++) {
      const id = Number(modelTimeSeries[i]?.id);
      if (!isNumber(id)) continue;

      const dateString = modelTimeSeries[i]?.date;
      const regEx = /(.*)-(.*)/;
      const match = dateString?.match(regEx);

      if (isNil(match)) continue;
      const year = Number(match[1]);
      const month = Number(match[2]);

      const subfilterTotal = (metricValue.category as CategoryTimeseries[])
        // skip values without id or not in subfilters
        .filter(
          (metricValue) =>
            hasNoCategoryMetadata(metricValue.metadata) ||
            subfilters.includes(`${metricValue.metadata?.id}`)
        )
        .reduce<{ count: number; share: number }>(
          (acc, metricValue) => {
            const timeSeriesCategoryValues = metricValue.timeseries?.[i];
            if (
              isNil(timeSeriesCategoryValues?.count) ||
              isNil(timeSeriesCategoryValues?.share)
            ) {
              return acc;
            } else {
              return {
                ...acc,
                count: acc.count + timeSeriesCategoryValues.count,
                share: acc.share + timeSeriesCategoryValues.share,
              };
            }
          },
          { count: 0, share: 0 }
        );
      const share = round(subfilterTotal.share / 100, 4);

      lineChartData.push({
        id,
        value: share,
        metadata: {
          shortName: dateString,
          longName: dateString,
          count: subfilterTotal.count,
          share,
          month,
          year,
        },
      });
    }

    return lineChartData;
  } else {
    return (metricValue.timeseries
      ?.map((timeDataPoint) => {
        const id = Number(timeDataPoint?.id);
        const value = timeDataPoint?.value;
        const dateString = timeDataPoint?.date;
        if (!id || isNil(value) || isNil(dateString)) return null;

        const regEx = /(.*)-(.*)/;
        const match = dateString?.match(regEx);

        if (isNil(match)) return null;
        const year = Number(match[1]);
        const month = Number(match[2]);

        return {
          id,
          value,
          metadata: {
            shortName: dateString,
            longName: dateString,
            count: value,
            share: value,
            month,
            year,
          },
        };
      })
      .filter((plotPoint) => !isNil(plotPoint)) || []) as LineValue[];
  }
};

/** ======== Convert Snapshot Values ======== */

function isCategoryMetric(
  metric: SingleValueMetric | CategoryMetric
): metric is CategoryMetric {
  return (metric as CategoryMetric).category !== undefined;
}

interface GetCompositionSnapshotValuesProps {
  metric: CompositionResponse['metrics'];
  plotName: CompositionSnapshotPlotName;
}
export const getCompositionSnapshotValues = ({
  metric,
  plotName,
}: GetCompositionSnapshotValuesProps):
  | BarChartHorizontalData['value']
  | StackedBarChartHorizontalData['value'] => {
  if (isNil(metric)) return [];

  const metricName = compositionSnapshotPlotNameMetricLookup[plotName];
  const metricValue = metric[metricName];

  if (isNil(metricValue)) return [];

  if (isCategoryMetric(metricValue)) {
    return (metricValue.category as CategoryTimeseries[])
      .map((categoryValue): StackedBarChartHorizontalValue | null => {
        if (
          isNotValidCategoryMetric(categoryValue) ||
          !isNumber(categoryValue.timeseries?.[0]?.share) ||
          !isNumber(categoryValue.timeseries?.[0]?.count)
        ) {
          return null;
        }

        const metaData = categoryValue.metadata as CompositionMetadata;
        const snapshotData = categoryValue.timeseries?.[0] as TimeDataPoint;

        return {
          id: metaData.id,
          value: (snapshotData.share as number) / 100,
          metadata: {
            shortName: metaData.shortName,
            longName: metaData.shortName,
            count: snapshotData.count as number,
          },
        };
      })
      .filter(
        (value): value is StackedBarChartHorizontalValue => !isNil(value)
      );
  } else {
    if (
      isNil(metricValue.timeseries) ||
      metricValue.timeseries.length === 0 ||
      isNil(metricValue.timeseries[0]?.value)
    ) {
      return [];
    }
    const snapshotData = metricValue.timeseries?.[0];
    return snapshotData.value as number;
  }
};

/** ======== Convert Composition Response ======== */
type CompositionPlotName =
  | CompositionOvertimePlotName
  | CompositionSnapshotPlotName;

type CompositionPlotData = (
  | Omit<LineData, 'value'>
  | Omit<BarChartHorizontalData, 'value'>
  | Omit<StackedBarChartHorizontalData, 'value'>
) & {
  value:
    | LineData['value']
    | BarChartHorizontalData['value']
    | StackedBarChartHorizontalData['value'];
};
interface ConvertCompositionResponseToPlotDataProps {
  entities: CompositionResponse[];
  plotName: CompositionPlotName;
  filters?: SerializedFiltersForQuery;
}

export const convertCompositionResponseToPlotData = ({
  entities,
  plotName,
  filters,
}: ConvertCompositionResponseToPlotDataProps): CompositionPlotData[] => {
  const isOvertimePlot = compositionOvertimePlotNames.includes(
    plotName as CompositionOvertimePlotName
  );

  return entities
    .map((record): CompositionPlotData | null => {
      const metadata = record.metadata;
      if (isNil(metadata)) return null;

      const { id, shortName, longName, type } = metadata;

      if (isNil(id) || isNil(shortName) || isNil(longName)) return null;

      return {
        id,
        metadata: { shortName, longName, type },
        value: isOvertimePlot
          ? getCompositionOvertimeValues({
              metrics: record.metrics,
              plotName: plotName as CompositionOvertimePlotName,
              subfilters: filters?.subfilter as string[],
            })
          : getCompositionSnapshotValues({
              metric: record.metrics,
              plotName: plotName as CompositionSnapshotPlotName,
            }),
      };
    })
    .filter((record): record is CompositionPlotData => !isNil(record));
};

/** ================================
 * GQL Queries
 ================================ */
export const COMPOSITION_GET_DATA = graphql(`
  query CompositionData($filters: Filters, $dim1: Dimension1) {
    composition(filters: $filters, dim1: $dim1, dim2: month) {
      metadata {
        id
        shortName
        longName
        type
        __typename
      }
      metrics {
        __typename
        # Single value metrics
        headcount {
          timeseries {
            id
            date
            value
          }
        }
        growth_rate {
          timeseries {
            id
            date
            value
          }
        }
        hiring_rate {
          timeseries {
            id
            date
            value
          }
        }
        attrition_rate {
          timeseries {
            id
            date
            value
          }
        }
        tenure {
          timeseries {
            id
            date
            value
          }
        }
        salary {
          timeseries {
            id
            date
            value
          }
        }

        # Category Metrics
        skills {
          category {
            metadata {
              id
              shortName
              longName
              type
              __typename
            }
            timeseries {
              id
              date
              label
              value
              count
              share
            }
          }
        }
        genders {
          category {
            metadata {
              id
              shortName
              longName
              type
              __typename
            }
            timeseries {
              id
              date
              count
              share
            }
          }
        }
        ethnicities {
          category {
            metadata {
              id
              shortName
              longName
              type
              __typename
            }
            timeseries {
              id
              date
              count
              share
            }
          }
        }
        educations {
          category {
            metadata {
              id
              shortName
              longName
              type
              __typename
            }
            timeseries {
              id
              date
              count
              share
            }
          }
        }
        geographies {
          category {
            metadata {
              id
              shortName
              longName
              type
              __typename
            }
            timeseries {
              id
              date
              count
              share
            }
          }
        }
        job_categories {
          category {
            metadata {
              id
              shortName
              longName
              type
              __typename
            }
            timeseries {
              id
              date
              count
              share
            }
          }
        }
        industries {
          category {
            metadata {
              id
              shortName
              longName
              type
              __typename
            }
            timeseries {
              id
              date
              count
              share
            }
          }
        }
      }
    }
  }
`);
