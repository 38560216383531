import { CompositionDataQuery, Dimension1 } from '@revelio/data-access';
import { GeographySummaryMetric, SummaryEntities } from '../../types';

export const getGeographyCompositionData = (
  data: CompositionDataQuery | undefined
) => {
  const entities = data?.composition;
  if (!entities) {
    return {
      industries: null,
      skills: null,
      job_categories: null,
    };
  }

  const getData = (metric: GeographySummaryMetric) =>
    getBarChartMetricData(entities, metric);

  return {
    industries: getData(GeographySummaryMetric.Industries),
    skills: getData(GeographySummaryMetric.Skills),
    job_categories: getData(GeographySummaryMetric.Roles),
  };
};

const getBarChartMetricData = (
  entities: SummaryEntities,
  metric: GeographySummaryMetric
) => {
  const category = entities?.[0]?.metrics?.[metric]?.category;
  if (!category) {
    return null;
  }

  const maxLength = category.length > 7 ? 8 : category.length - 1;

  return category
    .slice(0, maxLength)
    .map((category) => {
      const metaData = category?.metadata;
      const value = category?.timeseries?.[0]?.share;

      if (!metaData || !value) {
        return null;
      }

      return {
        id: metaData.id,
        value: value,
        metadata: {
          longName: metaData.longName,
          shortName: metaData.shortName,
          type: Dimension1.Geography,
        },
      };
    })
    .filter(Boolean);
};
