import { getAuthDashMetaCsrfToken, GO_API_ROOT } from '@revelio/auth';
import {
  Dimension1,
  CompensationDimension,
  GetCompensationDataQuery,
} from '@revelio/data-access';
import {
  DateRangeFormattedValues,
  GET_COMPENSATION_HELPER_DATA,
  SelectionCategories,
  useActiveFiltersState,
} from '@revelio/filtering';
import { useState, useEffect } from 'react';
import { useQuery } from 'urql';
import { KdeSalaryData } from './types';

//Assumption: we only want to show top roles/geo that are shared by all competitors
const getSharedIds = (
  compensationData: GetCompensationDataQuery['compensation']
) => {
  if (!compensationData || compensationData.length === 0) return [];

  // Extract the IDs from each compensation object
  const allIds = compensationData.map((compensation) =>
    compensation?.category?.map((category) => category?.metadata?.id)
  );

  // Find the intersection of all ID arrays
  const sharedIds = allIds.reduce((sharedIds, currentIds) => {
    return sharedIds?.filter((id) => currentIds?.includes(id));
  });

  // Return the first 6 shared IDs
  return (
    sharedIds?.slice(0, 6)?.map((id) => ({
      id,
      label: id,
    })) || []
  );
};

export const useGetKdePlotData = (
  primaryAndCompetitorRCIDs: number[] | undefined
) => {
  const hasNoPrimaryAndCompetitorRCIDs = !primaryAndCompetitorRCIDs?.length;
  const [{ data: competitorTopGeo, fetching: topGeoLoading }] = useQuery({
    query: GET_COMPENSATION_HELPER_DATA,
    variables: {
      dim1: Dimension1.Company,
      dim2: CompensationDimension.Geography,
      filters: { company: primaryAndCompetitorRCIDs },
    },
    pause: hasNoPrimaryAndCompetitorRCIDs,
  });

  const [{ data: competitorTopRole, fetching: topRoleLoading }] = useQuery({
    query: GET_COMPENSATION_HELPER_DATA,
    variables: {
      dim1: Dimension1.Company,
      dim2: CompensationDimension.Role,
      filters: { company: primaryAndCompetitorRCIDs },
    },
    pause: hasNoPrimaryAndCompetitorRCIDs,
  });

  const csrfToken = getAuthDashMetaCsrfToken();
  const [kdeSalaryData, setKdeSalaryData] = useState<KdeSalaryData | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [compensationLoading, setCompensationLoading] = useState(false);
  const activeFilters = useActiveFiltersState();

  useEffect(() => {
    setLoading(topGeoLoading || topRoleLoading || compensationLoading);
  }, [topGeoLoading, topRoleLoading, compensationLoading]);

  useEffect(() => {
    const topGeos = getSharedIds(competitorTopGeo?.compensation);
    const topRoles = getSharedIds(competitorTopRole?.compensation);

    if (
      hasNoPrimaryAndCompetitorRCIDs ||
      topGeos?.length === 0 ||
      topRoles?.length === 0
    ) {
      return;
    }

    setCompensationLoading(true);

    const fetchCompensationData = async () => {
      try {
        const dates = activeFilters.find(
          (filter) => filter.id === SelectionCategories.DATE_RANGE
        )?.value as DateRangeFormattedValues;

        const payload = {
          company: primaryAndCompetitorRCIDs,
          start_date: dates?.startDate,
          end_date: dates?.endDate,
          dim1: Dimension1.Company,
          top_geographies: getSharedIds(competitorTopGeo?.compensation),
          year_range: [[null, null]],
          top_roles: getSharedIds(competitorTopRole?.compensation),
        };

        const response = await fetch(`${GO_API_ROOT}/models/compensation`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-request-id': crypto.randomUUID(),
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        const data = await response.json();
        const kdeSalaryData: KdeSalaryData | undefined = data
          ?.find(
            (dataSet: { id: string }) => dataSet?.id === 'total_compensation'
          )
          .value?.find((plot: { id: string }) => plot.id === 'kde');

        setKdeSalaryData(kdeSalaryData);
        setCompensationLoading(false);
      } catch (error) {
        console.error('Failed to fetch compensation data:', error);
        setCompensationLoading(false);
      }
    };

    fetchCompensationData();
  }, [
    primaryAndCompetitorRCIDs,
    competitorTopGeo,
    competitorTopRole,
    csrfToken,
    activeFilters,
    setCompensationLoading,
    hasNoPrimaryAndCompetitorRCIDs,
  ]);

  return { kdeSalaryData, loading };
};
