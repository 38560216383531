import { PipelineColumnType } from '@revelio/data-access';
import {
  DEFAULT_GEO_PIPELINE_COLUMNS,
  DEFAULT_ROLE_PIPELINE_COLUMNS,
  STANDARD_COMPANY_COLUMN,
  ULTIMATE_PARENT_COMPANY_COLUMN,
} from './shared';
import { ColumnSet } from '@revelio/layout';

export const POSTING_INDIVIDUAL_COLUMNS: ColumnSet<PipelineColumnType>[] = [
  {
    heading: null,
    columns: [
      STANDARD_COMPANY_COLUMN,
      {
        id: PipelineColumnType.JobId,
        label: 'Posting ID',
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.PostDate,
        label: 'Post Date',
        isMandatory: true,
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.RemoveDate,
        label: 'Remove Date',
        isMandatory: true,
        defaultIsSelected: true,
      },
      DEFAULT_GEO_PIPELINE_COLUMNS,
      {
        ...DEFAULT_ROLE_PIPELINE_COLUMNS,
        menuItems: [
          {
            id: PipelineColumnType.JobCategory,
            label: 'Role k7',
            defaultIsSelected: true,
          },
          {
            id: PipelineColumnType.RoleK50,
            label: 'Role k50',
            defaultIsSelected: true,
          },
          {
            id: PipelineColumnType.RoleK150,
            label: 'Role k150',
            defaultIsSelected: true,
          },
          {
            id: PipelineColumnType.MappedRole,
            label: 'Role k1500',
          },
          {
            id: PipelineColumnType.JobtitleRaw,
            label: 'Raw Title',
            defaultIsSelected: true,
          },
          {
            id: PipelineColumnType.JobtitleTranslated,
            label: 'Translated Raw Title',
          },
        ],
      },
      {
        id: PipelineColumnType.Seniority,
        defaultIsSelected: true,
      },
      {
        id: PipelineColumnType.Description,
        label: 'Posting Description',
      },
      ULTIMATE_PARENT_COMPANY_COLUMN,
      {
        id: PipelineColumnType.Salary,
        label: 'Salary',
      },
    ],
    supportsCustomColumns: true,
  },
];
