import { SystemStyleObject, chakra } from '@chakra-ui/system';
import { useEffect$, useObservable } from '@ngneat/react-rxjs';
import {
  D3ChartComponentLookup,
  D3ChartNames,
  ID3ChartProps,
} from '@revelio/d3';
import {
  BuildDataRequest,
  FilterItem,
  LocalSelectionCategories,
  PlotConfigs,
  SelectionListIdNames,
  useDataBasedOnActiveFilters,
  useSingleOrMoreFilterState,
} from '@revelio/filtering';
import { Views, plotColorLookup } from '@revelio/core';
import { every, get, isEmpty, isUndefined } from 'lodash';
import {
  FunctionComponent,
  LegacyRef,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { withResizeDetector } from 'react-resize-detector';
import { CenterLoading, removeLoadingStatus } from '@revelio/core';
import { SankeyError } from '@revelio/assets';
import {
  BehaviorSubject,
  distinctUntilChanged,
  isObservable,
  of,
  pipe,
  tap,
  map,
} from 'rxjs';
import { SetOptional } from 'type-fest';
import { Flex, Text, useConst } from '@chakra-ui/react';
import { useKibanaLogger } from '../kibana-logger/kibana-logger';
import {
  PlotDataSortingFunctionLookup,
  defaultSortPlotData,
} from './sorting-functions';
import { CompositionResponse, Posting2dResponse } from '@revelio/data-access';
import { CombinedError } from 'urql';

export type PlotData =
  | (CompositionResponse | null)[]
  | (Posting2dResponse | null)[]
  | null;

export interface PlotProps
  extends SetOptional<Omit<PlotConfigs, 'name'>, 'preFetchQuery'> {
  disableD3?: boolean; // for testing without worrying about D3 stuff blowing up
  width?: number;
  height?: number;
  sx?: SystemStyleObject;
  targetRef?: LegacyRef<HTMLDivElement>;
  ignoreEmpty?: boolean;
  resetPlot?: boolean;
  isFullScreen?: boolean; // if plot is to be rendered in full screen mode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataProvider?: BehaviorSubject<Record<string, unknown> | Array<any>>;
  setPlotDimensions?: React.Dispatch<{ width: number; height: number }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  preFetchConfig?: any;
  requiredParams?: SelectionListIdNames[];
  removeQueryFromLoaderOnResponse?: boolean;
  includeInGlobalLoader?: boolean;
  isGqlQuery?: boolean;
  isGoRequest?: boolean;
  data?: PlotData;
  loading?: boolean;
  error?: CombinedError;
}

export interface NoDataComponentProps {
  text?: string;
  icon?: ReactNode;
}

function NoDataComponent(props: NoDataComponentProps) {
  return (
    <Flex data-testid="no-data-component" mt="30px" height="calc(100% - 30px)">
      {props.icon ? (
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDir="column"
        >
          {props.icon}
          <Text color="navyBlue.500" mt="5px">
            No data returned
          </Text>
        </Flex>
      ) : (
        props.text || 'No filters set or No data returned'
      )}
    </Flex>
  );
}

function InternalPlot({
  endpoint,
  requestMethod,
  brokenOutFilterIds,
  additionalNonActiveFilters,
  additionalOperatorsBeforeQuery,
  additionalOperators,
  chartTypeAndProps,
  disableD3 = false,
  width,
  height,
  sx,
  targetRef,
  ignoreEmpty = false,
  resetPlot = false,
  isFullScreen = false,
  setPlotDimensions,
  dataProvider,
  preFetchConfig = {},
  requiredParams,
  removeQueryFromLoaderOnResponse = false,
  includeInGlobalLoader = true,
  isGqlQuery = false,
  isGoRequest = false,
  view = Views.NONE,
  dataFetcher,
  data: dataProp,
  loading: loadingProp,
  error: errorProp,
}: PlotProps) {
  // TODO: create streaming map lookup of a plots data in a store or cache to access anywhere in the app

  const _additionalOperators = useRef(additionalOperators);
  const emitToDataProvider = useRef(() =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tap((data: any) => dataProvider?.next(data))
  );
  const isRenderingOrLoading = useConst(new BehaviorSubject(true));

  const [showPlotLoader, setShowPlotLoader] = useState(true);

  const { kibanaLogger } = useKibanaLogger();

  useEffect$(
    () =>
      isRenderingOrLoading.asObservable().pipe(
        distinctUntilChanged(),
        tap((isRorL) => {
          if (isRorL) {
            setShowPlotLoader(true);
          } else {
            setShowPlotLoader(false);
          }
        })
      ),
    []
  );

  if (dataProvider) {
    _additionalOperators.current = additionalOperators
      ? pipe(additionalOperators, emitToDataProvider.current())
      : emitToDataProvider.current();
  }

  const regConfigForQuery = {
    dataFetcher,
    endpoint,
    requestMethod,
    brokenOutFilterIds,
    additionalNonActiveFilters,
    additionalOperatorsBeforeQuery,
    additionalOperators: _additionalOperators.current,
    includeInGlobalLoader,
    requiredParams,
    removeQueryFromLoaderOnResponse,
    isGqlQuery,
    isGoRequest,
    view,
    skip: !!dataProp,
  } as BuildDataRequest;

  if (!isGqlQuery && kibanaLogger) {
    regConfigForQuery['kibanaLogger'] = kibanaLogger;
  }

  const [
    { loading: internalLoading, requestHash, data: internalData },
    internalError,
  ] = useDataBasedOnActiveFilters(regConfigForQuery);

  const data = dataProp || internalData;
  /** For passed in prop rendering, default loading to false since the internalLoading also stays false during data
   * fetch. If it becomes true, the <ChartComp /> gets torn down and re-rendered, causing longer load time of the
   * plot */
  const loading = isUndefined(loadingProp) ? internalLoading : false;
  const error = errorProp || internalError;

  // TODO: TYPE THIS
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sorted, setSorted] = useState<any>([]);

  const [primaryEntities, setPrimaryEntities] = useState([]);

  useSingleOrMoreFilterState(
    LocalSelectionCategories.PRIMARY_ENTITIES,
    pipe(
      map((ent) => {
        // TODO: TYPE THIS
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setPrimaryEntities(() => {
          if (!ent) return [];
          // TODO: TYPE THIS
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return ent.value.filter((val: FilterItem) => {
            return get(val, 'isActive', true);
          });
        });
      })
    )
  );

  const [typeAndProps] = useObservable(
    isObservable(chartTypeAndProps) ? chartTypeAndProps : of(chartTypeAndProps)
  );

  const { chartProps, chartType } = typeAndProps;

  useEffect(() => {
    const plotData = data;

    if (!plotData?.length) return;

    const sortingFunction = get(
      PlotDataSortingFunctionLookup,
      chartType,
      defaultSortPlotData
    );

    if (!sortingFunction) {
      setSorted([]);
      return;
    }

    const sortedData = sortingFunction(plotData, primaryEntities);

    if (sortedData.includes(undefined)) {
      setSorted([]);
      return;
    }

    setSorted(sortedData);
  }, [chartType, data, primaryEntities]);

  const ChartComp = useMemo(() => {
    return D3ChartComponentLookup[
      typeAndProps?.chartType || 'D3NoMatch'
    ] as FunctionComponent<ID3ChartProps>;
  }, [typeAndProps]);

  const hasAllProps = useMemo(
    () => every(typeAndProps.chartProps, (propVal) => !isUndefined(propVal)),
    [typeAndProps.chartProps]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isAllValuesEmpty = (data: any) => {
    return (
      Array.isArray(data) &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data?.every((item: any) => {
        return Array.isArray(item.value) && item.value.length === 0;
      })
    );
  };

  const isEmptyOrUndefinedData = useMemo(() => {
    return data ? isEmpty(data) || isAllValuesEmpty(data) : !data;
  }, [data]);

  const [result, setResult] = useState<ReactNode | string | undefined>(
    undefined
  );

  useEffect(() => {
    if (width && height) {
      setPlotDimensions?.({
        width: Math.floor(width),
        height: Math.floor(height),
      });
    }
  }, [height, setPlotDimensions, width]);

  useEffect(() => {
    if (error) {
      if (requestHash) {
        removeLoadingStatus([requestHash, 'tabChange']);
      }

      setResult(<NoDataComponent />);
      isRenderingOrLoading.next(false);
    } else if (!hasAllProps) {
      setResult(null);
      isRenderingOrLoading.next(true);
    } else if (loading) {
      setResult(null);
      isRenderingOrLoading.next(true);
    } else if (isEmptyOrUndefinedData && !loading && !ignoreEmpty) {
      if (requestHash) {
        removeLoadingStatus([requestHash, 'tabChange']);
      }

      setResult(<NoDataComponent />);
      isRenderingOrLoading.next(false);
    } else {
      setResult(undefined);
    }
  }, [
    loading,
    error,
    hasAllProps,
    isEmptyOrUndefinedData,
    ignoreEmpty,
    requestHash,
    isRenderingOrLoading,
  ]);

  const isEmptyDataForSankey =
    chartType === D3ChartNames.SankeyDiagram &&
    !data?.links.length &&
    !data?.nodes.length;

  const getThingToShow = (shouldShow: ReactNode | string | undefined) => {
    if (isUndefined(shouldShow)) {
      const fullScreenChartProps: {
        name: string;
        chartSize: string;
        chartPosition: string;
      } = {
        name: '-full',
        chartSize: 'large',
        chartPosition: 'right',
      };
      if ('name' in chartProps) {
        fullScreenChartProps.name = chartProps.name + '-full';
      }

      const propsToUse = {
        ...chartProps,
        ...(isFullScreen && {
          ...fullScreenChartProps,
        }),
      };

      // eslint-disable-next-line no-nested-ternary
      return disableD3 ? (
        'We have D3 data!'
      ) : (data && !isEmptyDataForSankey) || ignoreEmpty ? (
        <ChartComp
          {...propsToUse}
          requestHash={requestHash}
          data={
            /* eslint-disable-next-line no-nested-ternary */
            resetPlot ? [] : sorted.length > 0 ? sorted : data
          }
          isRenderingOrLoading={isRenderingOrLoading}
          widthHeight={{ width, height }}
          colorLookup={plotColorLookup.value}
        />
      ) : (
        <NoDataComponent icon={isEmptyDataForSankey ? <SankeyError /> : null} />
      );
    } else {
      return shouldShow;
    }
  };

  return (
    <chakra.div
      data-testid={`plot-${typeAndProps?.chartType}`}
      ref={targetRef}
      height="100%"
      maxHeight="90vh"
      sx={sx}
    >
      {getThingToShow(result)}
      <CenterLoading
        size="sm"
        shouldShow={showPlotLoader}
        background="#fff"
        position="absolute"
        top="0"
        left="0"
        borderRadius="10px"
      />
    </chakra.div>
  );
}

export const Plot = withResizeDetector(InternalPlot, {
  refreshMode: 'debounce',
  refreshOptions: {
    leading: false,
    trailing: true,
  },
});

export default Plot;
